import { createSelector } from '@reduxjs/toolkit';
import { GAME_MODE, PAGES, PAUSE_ON_HALFTIME, TURBO_LIVE_OFFSET_SECONDS } from '../constants/globals';

export const getHeaders = (state) => state?.app?.headers || [];

export const getAppName = (state) => state?.app?.appName;

export const getRoundData = (state) => state?.app?.roundData;

export const getRoundResults = (state) => state?.app?.roundResults;

export const getInitData = (state) => state?.app?.initData;

export const getMarkets = (state) => state?.app?.markets;

export const getEnabledLeauges = (state) => state?.app?.enabledLeagues;

export const getWinningScoresMap = (state) => state?.app?.winningScoresMap;

export const getPotentialWinLimit = 
  createSelector([getRoundData], (roundData) => roundData ? roundData.winCap / 100 : 0);

export const getSelectedLeague = (state) =>
  state?.app?.initData?.leagues.find((l) => l.id === state?.app?.selectedLeagueId);

export const getSelectedLeagueId = (state) => state?.app?.selectedLeagueId;

export const getDestinationUrls = (state) => state?.app?.initData?.destinations;

export const getConfigs = (state) => state?.app?.initData?.configs;

export const getGameMode = (state) => state?.app?.gameMode;

export const getNavigateToPage = (state) => state?.app?.navigateToPage;

const getInitMarkets = (state) => state?.app?.initData?.markets;

export const getAllMarketSelections = createSelector([getInitMarkets], (markets) => markets.reduce((acc, curr) => {
  if (curr.submarkets) {
    const selections = curr.submarkets.reduce(
      (subMarketSelections, subMarket) => ({ ...subMarketSelections, ...subMarket.selections }),
      {}
    );
    return { ...acc, ...selections };
  }
  const { selections } = curr;
  return { ...acc, ...selections };
}, {}));
  
const getMarketsWithSubmarkets = (state) => state?.app?.markets.filter((m) =>
  m.submarkets && m.submarkets.some((s) => s.enabled) ? true : m.enabled && !m.submarkets
);

const getOdds = (state) => state?.odds;

export const get5Markets = 
  createSelector([getMarketsWithSubmarkets, getOdds, getMarkets], (marketsWithSubmarkets, odds, markets) => {
    if (markets?.length === 0) {
      return null;
    }

    const first5 = markets?.slice(0, 4);
    const fifth = odds?.fifthMarketId
      ? marketsWithSubmarkets.find((x) => x.id === odds.fifthMarketId)
      : marketsWithSubmarkets[4];

    if (fifth) {
      first5.push(fifth);
    }
    return first5;
  });

export const getMarketsForDropdown = 
  createSelector([getMarkets, getMarketsWithSubmarkets, getOdds], (markets, marketsWithSubmarkets, odds) => {
    if (markets?.length === 0 || odds?.selectedMarketId === undefined) {
      return null;
    }

    const first5 = marketsWithSubmarkets.slice(0, 4);
    const fifth = odds.fifthMarketId
      ? marketsWithSubmarkets.find((x) => x.id === odds.fifthMarketId)
      : marketsWithSubmarkets[4];

    first5.push(fifth);
    return marketsWithSubmarkets.filter((market) => first5.findIndex((x) => x.id === market.id) === -1);
  });

export const getAllMarkets = (state) => state?.app?.markets;
export const getSelectedMarketId = (state) => state?.odds?.selectedMarketId;
export const getSelectedSubmarketId = (state) => state?.odds?.selectedSubmarketId;

export const getLeagues = createSelector([getEnabledLeauges], (enabledLeages) => {
  const leagues = [...enabledLeages];
  leagues.sort((a, b) => a.id - b.id);
  return leagues;
});

const getGamesForSelectedGameMode = (state) => {
  const gameMode = state?.app?.gameMode;
  return state?.app?.initData?.games.find((g) => g.id === gameMode);
};

export const getAllLeagues = createSelector([getGamesForSelectedGameMode], (game) => {
  if (!game) {
    return [];
  }
  const leagues = [...game.leagues];
  leagues.sort((a, b) => a.id - b.id);
  return leagues;
});

export const getCurrentRoute = (state) => state?.app?.currentRoute;

export const getLeaguesForLeagueMenu = 
createSelector(
  [getCurrentRoute, getRoundResults, getAllLeagues, getLeagues], 
  (currentRoute, roundResults, allLeagues, sortedLeagues) => {
    if (currentRoute !== PAGES.LIVE) {
      return sortedLeagues;
    }
    const competitionsDictionary =
    roundResults?.competitions.reduce((acc, curr) => {
      acc[curr] = curr;
      return acc;
    }, {}) || {};

    const leagues = allLeagues.filter((l) => competitionsDictionary[l.id]);
    leagues.sort((a, b) => a.id - b.id);

    return leagues;
  });

const getClassicGame = (state) => 
  state?.app?.initData?.games.find((g) => g.id === GAME_MODE.CLASSIC);

const getTurboGame = (state) => 
  state?.app?.initData?.games.find((g) => g.id === GAME_MODE.TURBO);
  
const getCashoutGame = (state) => 
  state?.app?.initData?.games.find((g) => g.id === GAME_MODE.CASH_OUT);

export const getLeaguesForLobby = 
  createSelector([getClassicGame, getTurboGame, getCashoutGame], (classicGame, turboGame, cashoutGame) => {
    const classicLeagues = classicGame?.leagues?.filter((l) => l.enabled) || [];
    classicLeagues.sort((a, b) => a.id - b.id);

    const turboLeagues = turboGame?.leagues?.filter((l) => l.enabled) || [];
    turboLeagues.sort((a, b) => a.id - b.id);

    const cashoutLeagues = cashoutGame?.leagues?.filter((l) => l.enabled) || [];
    cashoutLeagues.sort((a, b) => a.id - b.id);

    return {
      classicLeagues,
      turboLeagues,
      cashoutLeagues,
    };
  });

const bonusPercentages = (state) => state?.app?.roundData?.bonusPercentages.split('-');
export const getBonusSetting = createSelector([getRoundData, bonusPercentages], (roundData, percentages) => ({
  threshold: roundData?.bonusOddsThreshold ,
  percentages,
}));

export const getMarketOdds = 
  (createSelector(
    [getOdds, getInitData, getRoundData, getSelectedLeagueId], 
    (odds, initData, roundData, selectedLeagueId) => {
      if (odds === undefined || initData === null || roundData === null) {
        return [];
      }

      const { selectedMarketId, selectedSubmarketId } = odds;

      const selectedMarket = initData.markets.find((x) => x.id === selectedMarketId);

      let marketSelections;

      if (selectedSubmarketId !== undefined) {
        const submarket = selectedMarket.submarkets.filter((s) => s.enabled).find((x) => x.id === selectedSubmarketId);
        marketSelections = submarket.selections;
      } else {
        marketSelections = selectedMarket.selections;
      }

      const selectionIds = Object.keys(marketSelections);

      const leagueId = selectedLeagueId;
      const leagueIndex = roundData.competitions.findIndex((x) => x === leagueId);

      if (leagueIndex === -1) {
        return [];
      }

      const numOfMatches = roundData.matches[leagueIndex].split('|').length;
      const leagueOdds = roundData.odds[leagueIndex].split('|').map((x) => x.split('-'));

      const allSelections = roundData.selections[leagueIndex].split('-');

      const selectionIndexes = [];

      for (let i = 0; i < selectionIds.length; i += 1) {
        const selectionId = selectionIds[i];
        selectionIndexes.push(allSelections.findIndex((x) => x === selectionId));
      }

      const result = [];

      for (let matchIndex = 0; matchIndex < numOfMatches; matchIndex += 1) {
        const matchOdds = selectionIndexes.map((selectionIndex) => ({
          selection: marketSelections[allSelections[selectionIndex]],
          odd: leagueOdds[matchIndex][selectionIndex],
          selectionId: allSelections[selectionIndex],
          selectionIndex,
        }));
        result.push(matchOdds);
      }
      return result;
    }));

export const getSelectedOdds = (state) => state?.odds?.selectedOdds;

export const getBetslipSettings = (state) => state?.app?.initData?.betslipSettings;

export const getSelectedLeagueMatchesForRound = 
  createSelector(
    [getRoundData, getSelectedLeague, getSelectedLeagueId], (roundData, selectedLeague, selectedLeagueId) => {
      if (!roundData) {
        return [];
      }

      const selectedLeagueIndex = roundData.competitions.findIndex((x) => x === selectedLeagueId);
      if (selectedLeagueIndex === -1) {
        return [];
      }
      return roundData.matches[selectedLeagueIndex].split('|').map((m, index) => ({
        matchId: index,
        name: m,
        leagueFlag: selectedLeague.flag,
        leagueId: selectedLeague.id,
      }));
    });

export const getOpenMenus = (state) => state?.app?.openMenus;

export const getExternalIframeUrl = (state) => state?.app?.selectedExternalIframeUrl;

export const getAppConfig = (state) => state?.app?.initData?.configs;

export const getPlacedBetNumber = (state) => {
  const roundData = state?.app?.roundData;
  const currentRoundId = roundData?.roundId;

  if (!currentRoundId) {
    return 0;
  }

  const allPlacedBets = state?.app?.allPlacedBets;

  return Object.keys(allPlacedBets).reduce((acc, key) => (allPlacedBets[key]?.length || 0) + acc, 0);
};

export const getPlacedBetsTurboCount = (state) => {
  const roundData = state?.app?.roundData;
  const currentRoundId = roundData?.roundId;
  const allPlacedBets = state?.app?.allPlacedBets;

  return allPlacedBets[currentRoundId]?.length || 0;
};

export const getAllPlacedBets = (state) => state?.app?.allPlacedBets;

export const getPlacedBetSelections = createSelector(
  [getRoundData, getAllPlacedBets, getSelectedLeagueId], (roundData, placedBets, leagueId) => {
    const currentRound = roundData?.roundId;
    const placedBetsForRound = placedBets[currentRound];
  
    if (!placedBetsForRound) {
      return {};
    }
  
    const allSelections = placedBetsForRound
      .reduce((acc, curr) => acc.concat(curr.selections), [])
      .filter((l) => parseInt(l.leagueId) === leagueId);
  
    return allSelections.reduce((acc, curr) => {
      acc[curr.matchId] = {
        ...(acc[curr.matchId] || {}),
        [curr.selectionId]: curr,
      };
      return acc;
    }, {});
  });

export const getLeagueNameForShirtIcon = (state) => {
  const selectedLeague = getSelectedLeague(state);
  if (!selectedLeague) {
    return '';
  }

  return selectedLeague.name.toLowerCase().replace(/\s/g, '');
};

export const getActiveModal = (state) => state?.app?.activeModal;

export const getModalData = (state) => state?.app?.modalData;

export const getBonusLevels = (state) => state?.app?.initData?.bonusLeveling;

export const getTurboMatchLength = (state) => state?.app?.turboMatchLength;

export const getTurboHalftimeDuration = 
  createSelector(
    [getTurboMatchLength], 
    (turboMatchLength => {
      const halfTimeDuration = (((turboMatchLength * 1000) - PAUSE_ON_HALFTIME) / 2);

      if (turboMatchLength) {
        return halfTimeDuration;
      }
      
      return 0;
    }));

export const getLiveDates = 
  createSelector(
    [getRoundResults, getGameMode], 
    (roundResults, gameMode) => {
      if (!roundResults) {
        return null;
      }

      const startDate = new Date(roundResults.liveStartDate);
      const endDate = new Date(roundResults.liveEndDate);

      if (gameMode === GAME_MODE.TURBO) {
        startDate.setSeconds(startDate.getSeconds() + TURBO_LIVE_OFFSET_SECONDS);
        endDate.setSeconds(endDate.getSeconds() + TURBO_LIVE_OFFSET_SECONDS);
      }

      return {
        startDate,
        endDate,
      };
    });

export const getBetCountSchedule = (state) => state?.app?.betCountSchedule;

export const getPreviousClassicRoundId = (state) => state?.app?.previousRoundId;

export const getPreviousTurboRoundId = (state) => state?.app?.previousRoundIdTurbo;

export const getLastBetRoundTurbo = (state) => state?.app?.lastBetRoundTurbo;

export const getLoadingKickOff = (state) => state?.app?.isLoadingKickOff;

export const getCurrentWeek = (state) => {
  const selectedLeague = getSelectedLeague(state);
  if (!selectedLeague) {
    return null;
  }

  const roundData = getRoundData(state);

  if (!roundData) {
    return null;
  }

  const leagueIndex = roundData?.competitions.findIndex((l) => l === selectedLeague.id);

  if (leagueIndex < 0) {
    return null;
  }

  return roundData.weeks[leagueIndex];
};

export const getResultScores = createSelector([getRoundResults], (results) => {
  if (!results) {
    return {};
  }

  const scoreMap = {};
  const { matches, timeline } = results;

  if (!timeline) {
    return scoreMap;
  }
  matches.forEach((leagueMatches, leagueIndex) => {
    const homeAndAwayMatches = leagueMatches.split('|');
    const flattenedMatches = homeAndAwayMatches.reduce((acc, match) => acc.concat(match.split('-')), []);
    scoreMap[leagueIndex] = flattenedMatches.reduce((acc, curr, index) => {
      acc[index + 1] = 0;
      return acc;
    }, {});
  });

  timeline.forEach((data, leagueIndex) => {
    const dataPieces = data.split('|');
    dataPieces.forEach((d) => {
      const [, scoringTeamsString] = d.split(':');
      const scoringTeams = scoringTeamsString.split('-');

      // eslint-disable-next-line max-nested-callbacks
      scoringTeams.forEach((teamIndex) => {
        scoreMap[leagueIndex][teamIndex] += 1;
      });
    });
  });

  return scoreMap;
});

export const getPlaceBetsForRoundResults = (state) => state?.app?.placedBetsForRoundResults;

export const getCashoutMultiplier = (state) => state?.app?.cashoutMultiplier;
export const getCashoutSlotDuration = (state) => state?.app?.cashoutSlotDuration;
export const getCashoutNumberOfSlots = (state) => state?.app?.cashoutNumberOfSlots;

export const getRoundSelectionMap = (state) => state?.app?.roundData?.selections;
export const getRoundMatches = (state) => state?.app?.roundResults?.matches;
export const getRoundCompetitions = (state) => state?.app?.roundResults?.competitions;
export const getLastCashoutSlot = (state) => state?.app?.lastCashoutSlot;
export const getCashoutSlotClearance = (state) => state?.app?.cashoutSlotClearance;