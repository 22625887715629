import React, { useState, useMemo } from 'react';
import { useTranslationContext } from 'reactjs-polyglot';
import { useSelector } from 'react-redux';
import { MENUS, SVG_ICONS } from '../../constants/globals';
import { getBonusLevels, getOpenMenus } from '../../store/selectors';
import { formatFreeBets } from '../../utils/bonus';
import { convertToCentsDecimal } from '../../utils/common';
import { parseNum } from '../../utils/parser';
import BonusInformation from '../BonusInformation';
import SVGComponent from '../SVG/SVGComponent';

const LoyaltyMenuContent = ({ bets, currencySymbol, userBonus, time }) => {
  const { t } = useTranslationContext();

  const openMenus = useSelector(getOpenMenus);
  const bonusLevels = useSelector(getBonusLevels);

  const freeBets = useMemo(() => formatFreeBets(bets), [bets]);
  const bonusEmblems = useMemo(() => {
    if (!bonusLevels || !userBonus?.bonusName) {
      return [];
    }

    const currentBonusIndex = bonusLevels.findIndex((b) => b.name === userBonus.bonusName);

    if (currentBonusIndex === 0) {
      return [bonusLevels[currentBonusIndex + 1], bonusLevels[currentBonusIndex]];
    }

    if (currentBonusIndex === bonusLevels.length - 1) {
      return [bonusLevels[currentBonusIndex], bonusLevels[currentBonusIndex - 1]];
    }

    return [bonusLevels[currentBonusIndex + 1], bonusLevels[currentBonusIndex], bonusLevels[currentBonusIndex - 1]];
  }, [bonusLevels, userBonus?.bonusName]);

  const [bonusInfoOpen, setBonusInfoOpen] = useState(false);

  function toggleBonusInfo() {
    setBonusInfoOpen((prev) => !prev);
  }

  function isActiveEmblem(bonusName, currentUserBonus) {
    return bonusName.toLowerCase() === currentUserBonus.toLowerCase();
  }

  function getActiveEmblemClass(bonusName, currentUserBonus) {
    const name = bonusName.toLowerCase();
    return name === currentUserBonus.toLowerCase() ? `${name}-active` : name;
  }

  return (
    <div className="league-bar__bonusSection">
      <div className={`bonus ${openMenus[MENUS.BONUS] ? 'is-open' : ''}`}>
        <div className="bonus__emblems">
          {bonusEmblems.map((b) => (
            <div
              key={b.name}
              className={`bonus__emblems-item ${
                isActiveEmblem(b.name, userBonus?.bonusName) ? 'bonus__emblems-item--current' : ''
              }`}
            >
              <SVGComponent
                className={`bonus__emblems-icon ${
                  isActiveEmblem(b.name, userBonus?.bonusName) ? 'bonus__emblems-icon--current' : ''
                }`}
                src={`img/${SVG_ICONS.utility}#${getActiveEmblemClass(b.name, userBonus?.bonusName)}`}
              />
              <div className="text-uppercase">{t(`bonusLeveling.${b.id}.name`)}</div>
            </div>
          ))}
        </div>
        <div className="bonus__main">
          <div className="bonus__mainInfo">
            <div className="bonus__mainInfo-l text-uppercase">{t('common.word.bonus')}</div>
            <div className="bonus__mainInfo-m">
              <span className="bonus__mainInfo-amount">
                {parseNum(convertToCentsDecimal(userBonus?.bonusAmount || 0))} {currencySymbol}
              </span>
            </div>
            <div className="bonus__mainInfo-r">{userBonus?.active ? time : userBonus?.maxBonusDuration}</div>
          </div>
          <div className="bonus__mainProgress mt-3">
            <div className="bonus__mainProgressInner" style={{ width: `${userBonus?.completionPercentage}%` }}>
              <span className="bonus__mainProgressValue ml-1">{userBonus?.completionPercentage || 0}%</span>
            </div>
          </div>
          <div className="bonus__mainDetails mt-3">
            <p className="m-0">
              {t('loyaltyMenu.label.bonusDescription')}
            </p>
            <div className="bonus__mainDetailsBtn ml-3" onClick={toggleBonusInfo}>
              <SVGComponent className="md-icon" src={`img/${SVG_ICONS.utility}#icon__info`} />
            </div>
          </div>
        </div>

        {bonusInfoOpen ? (
          <BonusInformation isOpen={bonusInfoOpen} bonusLevels={bonusLevels} currency={currencySymbol} />
        ) : null}

        {freeBets.length > 0 ? (
          <div className="bonus__infoWrap">
            <div className="bonus__info">
              <div className="bonus__infoLabel text-uppercase">
                <span>{t('loyaltyMenu.label.freeBets')}</span>
              </div>
              <div className="bonus__info-inner">
                <div className="bonus__infoTxt">
                  {t('loyaltyMenu.label.freeBetsInformation')}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {freeBets.length > 0 ? (
          <div className="bonus__infoWrap">
            <div className="bonus__info bonus__info--light">
              <div className="bonus__infoLabel text-uppercase">
                <span>{t('loyaltyMenu.label.freeBetsAvailable')}</span>
                <div className="bonus__infoIcon">
                  <SVGComponent className="sm-icon ml-2" src={`img/${SVG_ICONS.utility}#freebet`} />
                  <span className="bonus__infoFreebet">{bets?.length}</span>
                </div>
              </div>
              <div className="bonus__infoTxt ml-3">
                {freeBets.map((b) => (
                  <div className="bonus__infoTxt-section" key={b.id}>
                    <div>
                      <strong>{b.bets.length} {t('common.word.bet').toLowerCase()}</strong> {t('loyaltyMenu.label.withAmount')}{' '}
                      <strong>
                        {parseNum(convertToCentsDecimal(b.stake))}
                        {currencySymbol}
                      </strong>
                      ,
                    </div>
                    <div>{t('loyaltyMenu.label.expiryDate', [b.date])}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LoyaltyMenuContent;
