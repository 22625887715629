import React from 'react';
import { useTranslationContext } from 'reactjs-polyglot';
import { useDispatch } from 'react-redux';
import { addStep } from 'user-step-tracker';
import { closeModal } from '../../store/reducer';

const FreeBetModal = () => {
  const { t } = useTranslationContext();
  const dispatch = useDispatch();

  function close() {
    addStep('USER_CLOSE_FREEBET_MODAL');
    dispatch(closeModal());
  }

  return (
    <div id="freebetModal" className="freebetModal">
      <div className="freebetModal__wrapper">
        <div className="freebetModal__content">
          <div className="freebetModal__giftTop" />
          <div className="freebetModal__giftBottom" />
          <div className="freebetModal__rays" />
          <div className="freebetModal__circle" />
          <div className="freebetModal__txt text-uppercase">
            <span>{t('freebet.label.youHave')}</span>
            <br />
            {t('freebet.label.free')}
            <br />
            {t('freebet.label.bets')}
          </div>
        </div>
        <div id="freebetModalBtn" className="freebetModal__btn" onClick={close}>
          {t('common.word.confirm')}
        </div>
      </div>
    </div>
  );
};

export default FreeBetModal;
