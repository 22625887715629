import { useState, useEffect } from 'react';
import { timeLeft } from '../../../utils/common';

// eslint-disable-next-line import/prefer-default-export
export function useLiveCountdownTimer(roundEndDate) {
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (roundEndDate) {
      setTimer(timeLeft(roundEndDate));
    } else {
      setTimer(null);
    }

    const intervalId = setInterval(() => {
      if (roundEndDate) {
        setTimer(timeLeft(roundEndDate));
      } else {
        setTimer(null);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [roundEndDate]);

  return timer;
}
