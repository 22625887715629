/**
 * find first submarket for selected market
 *
 * @function
 * @param {object} market
 * @returns {number}
 */
export const getMarketsFirstSubmarketId = (market) => {
  if (market.submarkets === undefined) {
    return undefined;
  }

  for (let i = 0; i < market.submarkets.length; i += 1) {
    const submarket = market.submarkets[i];
    if (submarket.enabled === true) {
      return submarket.id;
    }
  }

  return undefined;
};

/**
 * retutn index of selectionId in string
 *
 * @function
 * @param {string} selectionId
 * @param {string} leagueId
 * @param {object} roundSelectionsMap
 * @returns {number}
 */
export const getSelectionIndex = (selectionId, leagueId, roundSelectionsMap) => {
  const leagueSelectionsString = roundSelectionsMap[leagueId];
  if (roundSelectionsMap === undefined) {
    return -1;
  }
  return leagueSelectionsString.split('-').indexOf(selectionId.toString());
};

export default {};
