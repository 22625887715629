import React from 'react';
import { useSelector } from 'react-redux';
import { MODALS } from '../../constants/globals';
import { getActiveModal } from '../../store/selectors';
import FreeBetModal from './FreeBet';
import GeneralError from './GeneralError';
import MaxSelectionsModal from './MaxSelections';
import SessionExpired from './SessionExpired';

const Modal = () => {
  const activeModal = useSelector(getActiveModal);

  switch (activeModal) {
    case MODALS.SESSION_EXPIRED:
      return <SessionExpired />;
    case MODALS.FREE_BETS:
      return <FreeBetModal />;
    case MODALS.GENERAL_ERROR:
      return <GeneralError />;
    case MODALS.MAX_SELECTIONS:
      return <MaxSelectionsModal />;
    default:
      return null;
  }
};

export default Modal;
