import { createSlice } from '@reduxjs/toolkit';
import { GAME_MODE } from '../constants/globals';
import { remapMarketsToSelectionWinningScores } from '../utils/parser';

const initialState = {
  appName: 'Initial App state.',
  headers: null,
  roundData: null,
  roundResults: null,
  initData: null,
  markets: [],
  enabledLeagues: [],
  selectedLeagueId: null,
  openMenus: {},
  selectedExternalIframeUrl: '',
  timer: null,
  timerLive: null,
  allPlacedBets: {},
  placedBetsForRoundResults: {},
  winningScoresMap: null,
  navigateToPage: null,
  activeModal: null,
  modalData: {},
  gameMode: GAME_MODE.CASH_OUT,
  isLoadingKickOff: false,
  previousRoundId: null,
  previousRoundIdTurbo: null,
  lastBetRoundTurbo: null,
  betCountSchedule: {},
  currentRoute: null,
  cashoutMultiplier: 0,
  cashoutSlotDuration: null,
  cashoutNumberOfSlots: null,
  lastCashoutSlot: null,
  cashoutSlotClearance: null,
  turboMatchLength: 0
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setInitData(state, action) {
      state.initData = action.payload.initData;
      state.winningScoresMap = remapMarketsToSelectionWinningScores(action.payload.initData.markets);
      state.markets = action.payload.initData.markets;
    },
    startMainManager() {
      /** */
    },
    loadRound() {},
    navigateTo(state, action) {
      state.navigateToPage = action.payload.page;
    },
    setTimer(state, action) {
      state.timer = action.payload.timer;
      state.timerLive = action.payload.timerLive;
    },
    setRoundData(state, action) {
      state.roundData = action.payload.roundData;
      state.roundResults = action.payload.roundResults;

      if (!action.payload.roundResults) {
        return;
      }

      const placedBetsExists = state.allPlacedBets[action.payload.roundResults.roundId];

      if (placedBetsExists) {
        state.placedBetsForRoundResults = {
          [action.payload.roundResults.roundId]: state.allPlacedBets[action.payload.roundResults.roundId],
        };
      } else {
        state.placedBetsForRoundResults = {};
      }
    },
    setMarkets(state, action) {
      state.markets = action.payload.markets;
    },
    setEnabledLeagues(state, action) {
      state.enabledLeagues = action.payload.leagues;
    },
    fetchAppName(state, action) {
      state.appName = action.payload.property;
    },
    setAppName(state, action) {
      const { newState } = action.payload;
      state.appName = newState;
    },
    setHeaders(state, action) {
      const { headers } = action.payload;
      state.headers = headers;
    },
    setLeagueId(state, action) {
      state.selectedLeagueId = action.payload;
    },
    toggleMenu(state, action) {
      // always close all other menus when toggling a menu
      state.openMenus = { [action.payload.menu]: !state.openMenus[action.payload.menu] };
    },
    closeAllMenu(state) {
      state.openMenus = {};
    },
    goToAccountDeposit() {
      /** */
    },
    setExternalIFrameUrl(state, action) {
      state.selectedExternalIframeUrl = action.payload.selectedExternalIframeUrl;
    },
    goToMyAccount() {
      /** */
    },
    goToCashier() {
      /** */
    },
    getOpenBets() {
      /** */
    },
    loadRoundBets() {
      /** */
    },
    addPlacedBet(state, action) {
      const { allPlacedBets } = state;
      const { roundId, couponData } = action.payload;

      if (allPlacedBets[roundId] === undefined) {
        allPlacedBets[roundId] = [];
      }

      allPlacedBets[roundId].push(couponData);

      state.allPlacedBets = allPlacedBets;
    },
    addPlacedBetBulk(state, action) {
      const { allPlacedBets } = state;
      const { roundId, coupons } = action.payload;

      if (allPlacedBets[roundId] === undefined) {
        allPlacedBets[roundId] = [];
      }

      allPlacedBets[roundId] = [...allPlacedBets[roundId], ...coupons];

      state.allPlacedBets = allPlacedBets;
      state.placedBetsForRoundResults = allPlacedBets;
    },
    setActiveModal(state, action) {
      state.activeModal = action.payload.modal;
      state.modalData = action.payload.data;
    },
    closeModal(state) {
      state.activeModal = null;
      state.modalData = {};
    },
    setGameMode(state, action) {
      state.gameMode = action.payload.gameMode;
    },
    clearPlacedBets(state) {
      state.allPlacedBets = {};
    },
    kickOff(state) {
      state.isLoadingKickOff = true;
    },
    kickOffFail(state) {
      state.isLoadingKickOff = false;
    },
    kickOffSuccess(state) {
      state.isLoadingKickOff = false;
      state.lastBetRoundTurbo = null;
    },
    endLiveSaga() {
      /** call saga to perform actions needed when live ends in classic or turbo */
    },
    setLastBetRoundTurbo(state, action) {
      state.lastBetRoundTurbo = action.payload.roundId;
    },
    scheduleBetCount(state, action) {
      const { gameType, time, roundId } = action.payload;

      const schedule = state.betCountSchedule;
      schedule[roundId] = { gameType, time };
      state.betCountSchedule = schedule;
    },
    removeScheduledBetCount(state, action) {
      const { roundId } = action.payload;

      const schedule = state.betCountSchedule;
      delete schedule[roundId];

      const existingPlacedBets = { ...state.allPlacedBets };
      delete existingPlacedBets[roundId];
      state.allPlacedBets = { ...existingPlacedBets };

      state.betCountSchedule = schedule;
    },
    setCurrentRoute(state, action) {
      state.currentRoute = action.payload.route;
    },
    setCashoutParams(state, action) {
      state.cashoutMultiplier = action.payload.cashoutMultiplier;
      state.cashoutNumberOfSlots = action.payload.cashoutNumberOfSlots;
      state.cashoutSlotDuration = action.payload.cashoutSlotDuration;
    },
    setLastCashoutSlot(state, action) {
      state.lastCashoutSlot = action.payload;
    },
    setCashoutSlotClearance(state, action) {
      state.cashoutSlotClearance = action.payload;
    },
    setTurboMatchLength(state, action) {
      state.turboMatchLength = action.payload;
    }
  },
});

export default appSlice.reducer;

export const {
  fetchAppName,
  setAppName,
  setHeaders,
  startMainManager,
  loadRound,
  setRoundData,
  setInitData,
  setLeagueId,
  goToAccountDeposit,
  setExternalIFrameUrl,
  goToMyAccount,
  goToMyAccountSuccess,
  goToCashier,
  setTimer,
  getOpenBets,
  getOpenBetsSuccess,
  loadRoundBets,
  addPlacedBet,
  addPlacedBetBulk,
  navigateTo,
  setActiveModal,
  closeModal,
  toggleMenu,
  setGameMode,
  closeAllMenu,
  clearPlacedBets,
  setIsLive,
  kickOff,
  kickOffSuccess,
  kickOffFail,
  endLive,
  endLiveTurbo,
  endLiveSaga,
  setTurboRoundEndDate,
  setLastBetRoundTurbo,
  scheduleBetCount,
  removeScheduledBetCount,
  setCurrentRoute,
  setMarkets,
  setEnabledLeagues,
  setCashoutParams,
  setLastCashoutSlot,
  clearInitialCashoutSlot,
  setCashoutSlotClearance,
  setTurboMatchLength
} = appSlice.actions;
