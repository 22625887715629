import React from 'react';
import { useDispatch } from 'react-redux';
import { addStep } from 'user-step-tracker';
import { useTranslationContext } from 'reactjs-polyglot';
import { SVG_ICONS } from '../../constants/globals';
import { closeModal } from '../../store/reducer';
import SVGComponent from '../SVG/SVGComponent';

const MaxSelectionsModal = () => {
  const { t } = useTranslationContext();

  const dispatch = useDispatch();

  function close() {
    addStep('USER_CLOSE_MAX_SELECTIONS_MODAL');
    dispatch(closeModal());
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="text-center mb-3">
          <SVGComponent className="modal__content-icon" src={`${SVG_ICONS.utility}#betslip`} />
        </div>
        <div className="modal__content-title text-center">{t('modal.maxSelections.title')}</div>
        <div className="modal__content-subTitle mt-3 text-center">
          {t('modal.maxSelections.text')}
        </div>
        <div className="modal__content-btnWrap text-center" onClick={close}>
          <div className="btn btn--primary has-arrow">
            <span>{t('modal.maxSelections.ok')}</span> <SVGComponent className="btn__arrow" src={`${SVG_ICONS.utility}#arrow-right`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaxSelectionsModal;
