import { createSelector } from '@reduxjs/toolkit';
import { getFormattedBets } from '../helpers';

const getBetHistoryBets = (state) => state?.betHistory?.bets || {};
export const getBetHistories = createSelector([getBetHistoryBets], (betState) => {
  const keys = Object.keys(betState);
  keys.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

  return keys.reduce((acc, key) => {
    const bets = betState[key];
    const formattedBets = getFormattedBets(bets);
    const date = formattedBets[0] ? formattedBets[0].date : '';
    return acc.concat({
      date,
      bets: formattedBets,
    });
  }, []);
});

export const getBetHistoryState = (state) => state?.betHistory;

export const getCashedOutBets = (state) => state?.betHistory?.cashedOutBets;

export const getBetHistoryHasPending = (state) => {
  const betState = state?.betHistory?.bets || {};
  const dates = Object.keys(betState);

  return dates.some((date) => betState[date].some((bet) => bet.status === 0));
};

export const getSelectedBet = (state) => state?.betHistory?.selectedBet;
