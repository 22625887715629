import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LOADER_STATUS, PAGES } from '../constants/globals';
import { getBetslipState } from '../modules/betslip/store/selectors';
import { initBetslipState } from '../modules/betslip/store/slice';
import { removeAllOddSelections } from '../modules/odds/store/slice';
import { closeAllMenu } from '../store/reducer';
import BottomMenu from './BottomMenu';
import LeagueBarInfo from './LeagueBarInfo';
import TopBar from './TopBar';

const MainLayout = ({ children, selectedOdds }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { savingStatus } = useSelector(getBetslipState);

  function closeBetslip() {
    if (savingStatus === LOADER_STATUS.SUCCESS) {
      dispatch(initBetslipState());
      dispatch(removeAllOddSelections());
    }
    navigate(PAGES.ODDS);
  }

  function getCloseFunction() {
    switch (location.pathname) {
      case PAGES.BETSLIP:
        return closeBetslip;
      case PAGES.BET_DETAIL:
      case PAGES.MY_BETS:
      case PAGES.RESULTS:
      case PAGES.ODDS:
      case PAGES.CASHIER:
      case PAGES.MY_ACCOUNT:
      case PAGES.MY_DEPOSIT:
        return () => dispatch(closeAllMenu());
      default: {
        return null;
      }
    }
  }

  switch (location.pathname) {
    case PAGES.ODDS:
    case PAGES.BET_DETAIL:
    case PAGES.MY_ACCOUNT:
    case PAGES.MY_DEPOSIT:
    case PAGES.CASHIER:
    case PAGES.RESULTS:
    case PAGES.MY_BETS:
    case PAGES.BETSLIP:
    case PAGES.LIVE:
      return (
        <>
          <TopBar onClickClose={getCloseFunction()} />
          <LeagueBarInfo />
          {children}
          <BottomMenu selectedOdds={selectedOdds} />
        </>
      );
    default:
      return children;
  }
};

export default MainLayout;
