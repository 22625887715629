/* global 
  SockJS
*/

import { useEffect, useState } from 'react';
/**
 * taken from https://stackoverflow.com/questions/62768520/reconnecting-web-socket-using-react-hooks
 * @param {string} url
 * @returns object
 */
function reconnectingSocket(url) {
  let client;
  let isConnected = false;
  let reconnectOnClose = true;
  let messageListeners = [];
  let stateChangeListeners = [];

  function on(fn) {
    messageListeners.push(fn);
  }

  function off(fn) {
    messageListeners = messageListeners.filter((l) => l !== fn);
  }

  function onStateChange(fn) {
    stateChangeListeners.push(fn);
    return () => {
      stateChangeListeners = stateChangeListeners.filter((l) => l !== fn);
    };
  }

  function start() {
    const sockJsProtocols = ['websocket', 'xhr-streaming', 'eventsource', 'xhr-polling'];
    client = new SockJS(url, null, sockJsProtocols);

    client.onopen = () => {
      isConnected = true;
      stateChangeListeners.forEach((fn) => fn(true));
    };

    const { close } = client;

    // Close without reconnecting;
    client.close = () => {
      reconnectOnClose = false;
      close.call(client);
    };

    client.onmessage = (event) => {
      messageListeners.forEach((fn) => fn(event.data));
    };

    // eslint-disable-next-line no-console
    client.onerror = (e) => console.error(e);

    client.onclose = () => {
      isConnected = false;
      stateChangeListeners.forEach((fn) => fn(false));

      if (!reconnectOnClose) {
        // socket intentionally closed by app
        return;
      }

      // socket closed by server, so we start a new web socket connection
      setTimeout(start, 3000);
    };
  }

  start();

  return {
    on,
    off,
    onStateChange,
    close: () => client.close(),
    getClient: () => client,
    isConnected: () => isConnected,
  };
}

// eslint-disable-next-line import/prefer-default-export
export function useWebSocket(url) {
  const [socketObj, setSocketObj] = useState(null);

  useEffect(() => {
    if (!socketObj) {
      setSocketObj(reconnectingSocket(url));
    }
  }, [url, socketObj]);

  return {
    client: socketObj?.getClient(),
    isConnected: socketObj?.isConnected(),
  };
}
