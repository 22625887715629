import { COUNTRY_OFFSET } from '../constants/globals';
import { getCountryDateFromUTC, getCountryDateTime, getCountryNewDate } from './parser';

export function formatFreeBets(bets) {
  if (!bets) {
    return [];
  }

  const freeBets = [...bets];
  freeBets.sort((a, b) => new Date(a.expirationDate).getTime() - new Date(b.expirationDate).getTime());

  const groupedFreeBets = freeBets.reduce((acc, curr) => {
    const expiryDate = new Date(curr.expirationDate);
    const key = `${expiryDate.getTime()}${curr.stake}`;

    const { date, time, seconds } = getCountryDateTime(expiryDate.toISOString());
    const formattedDate = `${date} ${time}:${seconds}`;

    acc[key] = acc[key]
      ? { ...acc[key], bets: acc[key].bets.concat(curr) }
      : { id: key, date: formattedDate, stake: curr.stake, bets: [curr] };
    return acc;
  }, {});

  return Object.keys(groupedFreeBets).map((key) => groupedFreeBets[key]);
}

/**
 * Calculate bonus countdown time
 *
 * @function
 * @param {Time} expirationDate
 * @returns {void}
 */
export function calculateBonusTime(expirationDate) {
  if (!expirationDate) {
    return {
      d: 0,
      h: 0,
      m: 0,
      s: 0,
    };
  }
  const countryOffset = COUNTRY_OFFSET[process.env.BUNDLE_SKIN.toUpperCase()].OFFSET;
  const expDate = new Date(getCountryDateFromUTC(countryOffset, expirationDate));
  const countryNow = getCountryNewDate(process.env.BUNDLE_SKIN.toUpperCase());
  const diffSec = parseInt((expDate.getTime() - countryNow.getTime()) / 1000);
  let d = parseInt(diffSec / (3600 * 24));
  let h = parseInt((diffSec % (3600 * 24)) / 3600);
  let m = parseInt((diffSec % 3600) / 60);
  let s = parseInt((diffSec % 3600) % 60);

  if (diffSec <= 0) {
    d = 0;
    h = 0;
    m = 0;
    s = 0;
  }

  return {
    d,
    h,
    m,
    s,
  };
}

/**
 * Format time string for expanded bonus header
 *
 * @param timeObj
 * @function
 * @returns {string} Return days hours minutes and seconds if they exists
 */
export function formatBonusTime(timeObj) {
  if (!timeObj) {
    return '';
  }

  let timeString = '';
  if (timeObj.d > 0) {
    timeString += `${timeObj.d}d `;
  }
  if (timeObj.h > 0) {
    timeString += `${timeObj.h}h `;
  }

  const m = timeObj.m < 10 ? `0${timeObj.m}` : timeObj.m;
  const s = timeObj.s < 10 ? `0${timeObj.s}` : timeObj.s;
  return `${timeString} ${m}' ${s}"`;
}

/**
 * Format time string for collapsed bonus header
 *
 * @param time
 * @function
 * @returns {string} Return days or hours or minutes and seconds
 */
export function formatShortBonusTime(time) {
  if (!time) {
    return '';
  }

  if (time.d > 0) {
    return time.d === 1 ? `${time.d} day` : `${time.d} days`;
  }
  if (time.h > 0) {
    return `${time.h}hr`;
  }

  if (time.m > 0 || time.s > 0) {
    const m = time.m < 10 ? `0${time.m}` : time.m;
    const s = time.s < 10 ? `0${time.s}` : time.s;
    return `${m}' ${s}"`;
  }
  return '';
}
