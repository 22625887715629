import React from 'react';

const Splash = () => (
  <>
    <div className="top-bar">
      <div className="top-bar__l" />
      <div className="top-bar__m">
        <span className="top-bar__logo" />
      </div>
      <div className="top-bar__r" />
    </div>
    <div className="scroll-area">
      <div className="lobby is-loading">
        <div className="lobby__logo mt-4" />
        <div className="loader my-4">
          <div className="loader__inner" />
        </div>
        <div className="spacer flex-grow-1" />
      </div>
    </div>
  </>
);

export default Splash;
