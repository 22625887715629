import { createSlice } from '@reduxjs/toolkit';
import { PAGES } from '../../../constants/globals';
import { applyServerOffset } from '../../../utils/common';

const initialState = {
  isLoading: false,
  user: null,
  loginError: null,
  targetPageAfterLogin: PAGES.HOME,
  bonus: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state) {
      state.isLoading = true;
      state.loginError = null;
    },
    loginSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload.user;
      state.targetPageAfterLogin = action.payload.targetPageAfterLogin
        ? action.payload.targetPageAfterLogin
        : PAGES.HOME;
    },
    loginFail(state, action) {
      state.isLoading = false;
      state.loginError = action.payload;
    },
    setUserData(state, action) {
      state.user = action.payload.user;
    },
    checkSession(state) {
      state.isLoading = true;
    },
    checkSessionFail(state) {
      state.isLoading = false;
    },
    checkSessionSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload.user;
    },
    logout(state) {
      state.user = null;
      state.loginError = null;
      state.targetPageAfterLogin = PAGES.HOME;
    },
    getUserBalance(state) {
      state.isLoading = true;
    },
    getUserBalanceFail(state) {
      state.isLoading = false;
    },
    getUserBalanceSuccess(state, action) {
      state.isLoading = false;
      state.user = {
        ...state.user,
        data: {
          ...state.user.data,
          balance: { ...action.payload.balance, lastUpdatedDate: applyServerOffset(new Date()).toISOString() },
        },
      };
    },
    updateUserAfterPlaceBet(state, action) {
      const freeBets = action.payload.freeBets || state.user.freeBets;
      const balance = action.payload.balance.amount ? action.payload.balance : state.user.data.balance;
      state.user = {
        ...state.user,
        freeBets,
        data: { ...state.user.data, balance },
      };
    },
    loadUserLoyalty() {
      /** */
    },
    loadUserLoyaltySuccess(state, action) {
      state.bonus = action.payload.userBonus;
    },
  },
});

export default authSlice.reducer;

export const {
  login,
  loginSuccess,
  loginFail,
  setUserData,
  checkSession,
  checkSessionFail,
  checkSessionSuccess,
  logout,
  getUserBalance,
  getUserBalanceSuccess,
  updateUserAfterPlaceBet,
  loadUserLoyalty,
  loadUserLoyaltySuccess,
  getUserBalanceFail,
} = authSlice.actions;
