import { put, call, takeEvery, select } from 'redux-saga/effects';
import { t } from 'reactjs-polyglot';
import { getLastSteps } from 'user-step-tracker';
import {
  API_URLS,
  CASHOUT_ERRORS,
  ERRORS,
  GAME_MODE,
  MODALS,
  PAGES,
} from '../../../constants/globals';
import {
  addPlacedBet,
  scheduleBetCount,
  setActiveModal,
  setLastBetRoundTurbo,
  setRoundData,
  navigateTo,
} from '../../../store/reducer';
import { getGameMode, getRoundData, getRoundResults, getWinningScoresMap } from '../../../store/selectors';
import { requestPost } from '../../../utils/request';
import { parseRoundBetToCouponData } from '../utils/bet-parser';
import { updateUserAfterPlaceBet, loadUserLoyalty, checkSession } from '../../auth/store/slice';
import { placeBet as placeBetAction, placeBetFail, placeBetSuccess } from './slice';
import { betCashoutFail, betCashoutSuccess, tryCashoutSaga } from '../../bet-history/store/slice';
import { getUserEnabledGames } from '../../auth/store/selectors';
import { logError } from '../../../utils/common';

function* setRoundEndDate(placeBetResponse) {
  const gameMode = yield select(getGameMode);
  const roundData = yield select(getRoundData);
  const roundResults = yield select(getRoundResults);

  let roundEndTime = null;

  if (gameMode === GAME_MODE.TURBO) {
    roundEndTime = new Date(placeBetResponse.data.round.endDate).toISOString();
  } else {
    // note we're using data from previous round to know the round duration, because there is no other way of
    // knowing the duration of live until the next round starts
    const roundDuration = roundResults?.liveRoundDuration || 0;
    const date = new Date(roundData.endDate);
    date.setSeconds(date.getSeconds() + roundDuration);
    roundEndTime = date.toISOString();
  }

  roundEndTime = new Date(Date.parse(roundEndTime)).toISOString();

  yield put(
    scheduleBetCount({
      roundId: roundData.roundId,
      time: roundEndTime,
      gameType: gameMode === GAME_MODE.TURBO ? GAME_MODE.TURBO : GAME_MODE.CASH_OUT,
    })
  );
}

function* placeBet(action) {
  try {
    const { placedBets, selections, couponOdds, bonusSetting, boostBonus, bonusPercentage, ...requestBody } =
      action.payload;
    const userEnabledGames = yield select(getUserEnabledGames);
    const currentGameIsEnabled = userEnabledGames.some((g) => g === requestBody.gameId);

    if (!currentGameIsEnabled) {
      yield put(navigateTo({ page: PAGES.HOME }));
      return;
    }

    const response = yield call(requestPost, API_URLS.PLACEBET, requestBody, true);

    if (response.status === -1) {
      const errorsRelatedToSession = [ERRORS.SESSION_EXPIRED, ERRORS.AUTH_REQUIRED, ERRORS.ERROR_DURING_AUTH];
      const hasErrorsRelatedToSession = errorsRelatedToSession.some((error) => response.error.code === error);
      if (response.error.code === ERRORS.PLACEBET_COUPON_VALIDATION_FREE_BET_ERROR) {
        yield put(checkSession());
      }
      yield put(placeBetFail(hasErrorsRelatedToSession ? '' : response.error.message));
      const lastSteps = getLastSteps();

      logError({
        info: 'Placebet error',
        errorMessage: response.error.message,
        lastSteps: lastSteps.slice(lastSteps.length - 5),
        data: {
          betData: requestBody.betData,
          freeBetId: requestBody.freeBetId,
          gameId: requestBody.gameId,
          maxPotWin: requestBody.maxPotWin,
          round: requestBody.round,
          isTurbo: requestBody.turbo,
          odds: selections.map((s) => s.odd),
          boostBonus,
          bonusPercentage,
          bonusSetting: {
            threshold: bonusSetting.threshold,
            percentages: bonusSetting.percentages.join('-'),
          },
        },
      });
      return;
    }

    const winningScoresMap = yield select(getWinningScoresMap);
    const gameMode = yield select(getGameMode);
    const isTurbo = gameMode === GAME_MODE.TURBO;

    yield put(
      addPlacedBet({
        roundId: requestBody.round,
        couponData: parseRoundBetToCouponData(
          requestBody.betData,
          selections,
          winningScoresMap,
          requestBody.maxPotWin,
          response.data.couponId,
          requestBody.round,
          couponOdds,
          !!requestBody.freeBetId
        ),
      })
    );

    yield setRoundEndDate(response);

    if (isTurbo && response.data.round) {
      yield put(setLastBetRoundTurbo({ roundId: response.data.round.roundId }));
      yield put(
        setRoundData({
          roundData: response.data.round,
          roundResults: null,
        })
      );
    }
    yield put(placeBetSuccess());

    yield put(loadUserLoyalty({ isAfterPlacebet: true }));

    const { freeBets, ...balance } = response.data;
    yield put(updateUserAfterPlaceBet({ freeBets, balance }));
  } catch (error) {
    const errorsRelatedToSession = [ERRORS.SESSION_EXPIRED, ERRORS.AUTH_REQUIRED, ERRORS.ERROR_DURING_AUTH];
    const hasErrorsRelatedToSession = errorsRelatedToSession.some((e) => error.code === e);
    yield put(placeBetFail(hasErrorsRelatedToSession ? '' : error.message));
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* cashout(action) {
  try {
    const { couponId, cashoutValue, slotId, roundId, data } = action.payload;
    const requestBody = { couponId, cashoutValue, slotId };
    const response = yield call(requestPost, API_URLS.CASH_OUT, requestBody, true);

    if (response.status === -1) {
      yield put(betCashoutFail({ roundId, couponId }));
      const cashoutSettlementErrors = [
        CASHOUT_ERRORS.CASHOUT_VALIDATION_SLOT_ERROR,
        CASHOUT_ERRORS.CASHOUT_VALIDATION_COUPON_ALREADY_CASHOUT_ERROR,
        CASHOUT_ERRORS.CASHOUT_VALIDATION_COUPON_SETTLED_ERROR,
        CASHOUT_ERRORS.CASHOUT_SETTLEMENT_FAILED,
        CASHOUT_ERRORS.CASHOUT_SETTLEMENT_ERROR,
      ];

      const errorIsSettlementRelatedError = cashoutSettlementErrors.some((code) => code === response.error.code);

      logError({
        info: 'Cashout error',
        errorMessage: response.error.message,
        data,
      });

      if (errorIsSettlementRelatedError) {
        yield put(
          setActiveModal({
            modal: null,
            data: {
              couponId,
              errorCode: response.error.code,
              errorMessage: t('error.cashoutValueHasChanged'),
            },
          })
        );
        return;
      }

      yield put(
        setActiveModal({
          modal: MODALS.GENERAL_ERROR,
          data: {
            error: t('error.cantCashOut')
          },
        })
      );
      return;
    }

    yield put(betCashoutSuccess({ roundId, couponId, cashoutValue }));
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

export default function* betslipSaga() {
  yield takeEvery(placeBetAction, placeBet);
  yield takeEvery(tryCashoutSaga, cashout);
}
