export default function throttle(callback, delay) {
  let timerId = null;

  function cb(...args) {
    if (timerId) return;

    callback.call(this, ...args);
    timerId = setTimeout(() => {
      timerId = null;
    }, delay);
  }

  return cb;
}
