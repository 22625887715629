import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslationContext } from 'reactjs-polyglot';
import { useNavigate } from 'react-router-dom';
import { addStep } from 'user-step-tracker';
import { MENUS, PAGES } from '../constants/globals';
import { getAppConfig, getOpenMenus, getPlacedBetNumber } from '../store/selectors';
import { getUserBalance as getUserBalanceAction, logout as logoutAction } from '../modules/auth/store/slice';
import { getUser } from '../modules/auth/store/selectors';
import { parseNum } from '../utils/parser';
import { convertToCentsDecimal } from '../utils/common';
import throttle from '../utils/throttle';
import { toggleMenu } from '../store/reducer';

const UserMenu = () => {
  const { t } = useTranslationContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openMenus = useSelector(getOpenMenus);
  const user = useSelector(getUser);
  const config = useSelector(getAppConfig);
  const myBetsCount = useSelector(getPlacedBetNumber);

  const throttledGetUserBalance = useMemo(
    () =>
      throttle(() => {
        dispatch(getUserBalanceAction());
      }, 10000),
    [dispatch]
  );

  const getUserBalance = useCallback(() => {
    addStep('USER_GET_BALANCE_UserMenu');
    throttledGetUserBalance();
  }, [throttledGetUserBalance]);

  function logout() {
    dispatch(logoutAction());
    dispatch(toggleMenu({ menu: MENUS.ACCOUNT }));
    navigate(PAGES.ODDS);
  }

  function navigateToMyAccount() {
    addStep('USER_NAVIGATE_MY_ACCOUNT_UserMenu');
    dispatch(toggleMenu({ menu: MENUS.ACCOUNT }));
    navigate(PAGES.MY_ACCOUNT);
  }

  function navigateToMyDeposit() {
    addStep('USER_NAVIGATE_MY_DEPOSIT_UserMenu');
    dispatch(toggleMenu({ menu: MENUS.ACCOUNT }));
    navigate(PAGES.MY_DEPOSIT);
  }

  function navigateToMyBets() {
    addStep('USER_NAVIGATE_MY_BETS_UserMenu');
    dispatch(toggleMenu({ menu: MENUS.ACCOUNT }));
    navigate(PAGES.MY_BETS);
  }

  function navigateToCashier() {
    addStep('USER_NAVIGATE_CASHIER_UserMenu');
    dispatch(toggleMenu({ menu: MENUS.ACCOUNT }));
    navigate(PAGES.CASHIER);
  }

  return (
    <div className={`myAccount ${openMenus[MENUS.ACCOUNT] ? 'is-open' : ''}`}>
      <div className="myAccount__menu">
        <div className="myAccount__balance">
          <div className="myAccount__balanceItem">
            <div className="myAccount__balanceRefresh" onClick={getUserBalance} />
            <div className="myAccount__balanceInfo ml-2">
              <div className="myAccount__balanceTitle">{t('common.word.balance')}</div>
              <div className="myAccount__balanceAmount">
                {user?.balance?.amount ? parseNum(convertToCentsDecimal(user.balance.amount)) : 0}{' '}
                {config?.currencySymbol}
              </div>
            </div>
          </div>
          <div className="myAccount__balanceItem" onClick={navigateToMyDeposit}>
            <div className="myAccount__balanceBtn">{t('common.word.deposit')}</div>
          </div>
        </div>
        <div className="myAccount__nav">
          <div className="myAccount__navItem" onClick={navigateToMyAccount}>
            <span>{t('common.word.myAccount')}</span>
          </div>
          <div className="myAccount__navItem" onClick={navigateToMyBets}>
            <span>{t('common.word.myBets')}</span> <span className="myAccount__navItem-notif ml-2">{myBetsCount}</span>
          </div>
          <div className="myAccount__navItem" onClick={navigateToCashier}>
            <span>{t('common.word.cashier')}</span>
          </div>
          <div className="myAccount__navItem" onClick={logout}>
            <span>{t('common.word.logout')}</span>
          </div>
        </div>
      </div>
      <div className="myAccount__user">
        <div className="myAccount__userWrap">
          <div className="myAccount__userTitle">{t('common.word.accountId')}:</div>
          <div className="myAccount__userValue">{user?.id}</div>
          <div className="myAccount__userTitle">{t('common.word.username')}:</div>
          <div className="myAccount__userValue">{user?.username}</div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;
