import React, { useMemo } from 'react';
import { useTranslationContext } from 'reactjs-polyglot';
import LS_CONSTANTS from 'build-utils-react/config/constants';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { addStep } from 'user-step-tracker';
import { SinglesMinMaxWinCalculator } from 'system-manager';
import SVGComponent from './SVG/SVGComponent';
import { BET_TYPE, GAME_MODE, MENUS, PAGES, SVG_ICONS } from '../constants/globals';
import {
  getBonusSetting,
  getPotentialWinLimit,
  getOpenMenus,
  getGameMode,
  getLiveDates,
  getLoadingKickOff,
  getPlacedBetsTurboCount,
  getPlacedBetNumber,
} from '../store/selectors';
import { convertToCentsDecimal, formatToDecimal, getBetType, parseBigNums } from '../utils/common';
import { oddsByMatches, SystemMatches, SystemSplitManager } from '../utils/system-manager';
import { getIsAuthenticated } from '../modules/auth/store/selectors';
import { kickOff, toggleMenu } from '../store/reducer';
import { useIsLive } from '../modules/live/hooks/useIsLive';
import { getOddsLoadingStatus } from '../modules/odds/store/selectors';

// eslint-disable-next-line complexity
const BottomMenu = ({ selectedOdds }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslationContext();

  const bonusSetting = useSelector(getBonusSetting);
  const potentialWinLimit = useSelector(getPotentialWinLimit);
  const openMenu = useSelector(getOpenMenus);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const myBetsCount = useSelector(getPlacedBetNumber);
  const turboBetsCount = useSelector(getPlacedBetsTurboCount);
  const liveDates = useSelector(getLiveDates);
  const gameMode = useSelector(getGameMode);
  const isLoadingKickOff = useSelector(getLoadingKickOff);
  const isLoadingOdds = useSelector(getOddsLoadingStatus);

  const isLive = useIsLive(liveDates?.startDate, liveDates?.endDate);

  const showKickOffButton =
    !isLive &&
    location.pathname.includes(PAGES.ODDS) &&
    gameMode === GAME_MODE.TURBO &&
    turboBetsCount > 0 &&
    !isLoadingOdds;

  const maxOdds = useMemo(() => {
    let odds = selectedOdds.map((s) => s.odd);
    const betType = getBetType(selectedOdds);
    const isSplitBet = betType === BET_TYPE.SPLIT_BET;

    if (!bonusSetting?.percentages || !bonusSetting.threshold) {
      return 0;
    }

    if (isSplitBet) {
      const sysMatches = SystemMatches(selectedOdds);
      odds = sysMatches.oddsPerMatch;
    }

    const sysManager = SystemSplitManager(
      odds,
      bonusSetting.percentages,
      bonusSetting.threshold,
      isSplitBet,
      potentialWinLimit
    );

    switch (betType) {
      case BET_TYPE.SINGLE: {
        const stakes = selectedOdds.map(() => 100);
        const formattedOdds = oddsByMatches(selectedOdds, stakes);
        const singlesMinMaxOdd = SinglesMinMaxWinCalculator(formattedOdds, potentialWinLimit);
        return formatToDecimal(convertToCentsDecimal(singlesMinMaxOdd.max));
      }
      case BET_TYPE.SPLIT_BET:
        return sysManager?.ODDMAX.toFixed(2);
      default: {
        const keys = Object.keys(sysManager);
        return sysManager[keys[keys.length - 1]]?.ODDMAX.toFixed(2);
      }
    }
  }, [bonusSetting, potentialWinLimit, selectedOdds]);

  function navigateTo(url) {
    addStep(`USER_NAVIGATE_${url.toUpperCase()}`);
    navigate(url);
  }

  function openSideMenu() {
    addStep('USER_OPEN_SIDE_MENU');
    dispatch(toggleMenu({ menu: MENUS.SIDE }));
  }

  function oddMenuItemActive() {
    return !openMenu[MENUS.SIDE] && location.pathname.includes(PAGES.ODDS);
  }

  function myBetsMenuItemActive() {
    return !openMenu[MENUS.SIDE] && location.pathname.includes(PAGES.MY_BETS);
  }

  function resultsMenuItemActive() {
    return !openMenu[MENUS.SIDE] && location.pathname.includes(PAGES.RESULTS);
  }

  function isLivePage() {
    return location.pathname.includes(PAGES.LIVE);
  }

  function navigateToMyBets() {
    if (isAuthenticated) {
      addStep('USER_NAVIGATE_MY_BETS');
      navigate(PAGES.MY_BETS);
      return;
    }
    addStep('USER_NAVIGATE_MY_BETS_REDIRECT_LOGIN');
    navigate(PAGES.LOGIN);
  }

  function kickOffMatch() {
    addStep('USER_CLICK_KICK_OFF');
    if (!isLoadingKickOff) {
      dispatch(kickOff());
    }
  }

  function getSVGIconUrl() {
    if (process.env.SKIN === LS_CONSTANTS.SKINS.COLOMBIA) {
      return SVG_ICONS.bottomMenuBlue;
    }
    return SVG_ICONS.bottomMenu;
  }

  function getTranslatedBetTypeLabel() {
    const betTypeForSelectedOdds = getBetType(selectedOdds);

    switch (betTypeForSelectedOdds) {
      case BET_TYPE.SINGLE:
        return t('betTypeLabel.SINGLE');
      case BET_TYPE.MULTIPLE:
        return t('betTypeLabel.MULTIPLE');
      case BET_TYPE.COMBINATION:
        return t('betTypeLabel.COMBINATION');
      case BET_TYPE.SPLIT_BET:
        return t('betTypeLabel.SPLIT_BET');
      case BET_TYPE.FREEBET:
        return t('betTypeLabel.FREEBET');
      default:
        return '';
    }
  }

  return (
    <>
      <div className="bottom-menu__info">
        {showKickOffButton ? (
          <div className="bottom-menu__infoTurbo" onClick={kickOffMatch}>
            <div className="bottom-menu__infoTurbo-l">
              <i className="bottom-menu__infoTurboIcon icon-ball mr-3" /> <span>{t('common.word.kickOff')}</span>
            </div>
            <div className="bottom-menu__infoTurbo-r">
              <SVGComponent className="sm-icon ml-3" src={`${getSVGIconUrl()}#arrow-right`} />
            </div>
          </div>
        ) : null}
        {selectedOdds.length > 0 ? (
          <div className="bottom-menu__infoBetslip">
            <div className="bottom-menu__info-betType text-right">{getTranslatedBetTypeLabel()}</div>
            <div className="bottom-menu__info-gutter" />
            <div className="bottom-menu__info-odds txt-cut">{parseBigNums(maxOdds)}</div>
          </div>
        ) : null}
      </div>
      <div className="bottom-menu">
        <div
          id="menu-home"
          className={`bottom-menu__item ${openMenu[MENUS.SIDE] ? 'is-active' : ''}`}
          onClick={openSideMenu}
        >
          <SVGComponent
            className="bottom-menu__item-icon"
            src={`${getSVGIconUrl()}#${openMenu[MENUS.SIDE] ? 'menu-active' : 'menu'}`}
          />
          <span className="bottom-menu__item-label">{t('common.word.menu')}</span>
        </div>
        {isLive && !isLivePage() ? (
          <div className="bottom-menu__item" onClick={navigateTo.bind(this, PAGES.LIVE)}>
            <SVGComponent className="bottom-menu__item-icon" src={`${getSVGIconUrl()}#live`} />
            <span className="bottom-menu__item-label">{t('common.word.live')}</span>
          </div>
        ) : (
          <div
            className={`bottom-menu__item ${oddMenuItemActive() ? 'is-active' : ''}`}
            onClick={navigateTo.bind(this, PAGES.ODDS)}
          >
            <SVGComponent
              className="bottom-menu__item-icon"
              src={`${getSVGIconUrl()}#${oddMenuItemActive() ? 'market-active' : 'market'}`}
            />
            <span className="bottom-menu__item-label">{t('common.word.odds')}</span>
          </div>
        )}
        <div
          id="betslipBtn"
          className={`bottom-menu__item bottom-menu__item--betSlip ${selectedOdds.length < 1 ? 'is-inactive' : ''}`}
        >
          <div className="bottom-menu__item-betSlipCountWrapper">
            <div className="bottom-menu__item-betSlipCount" onClick={navigateTo.bind(this, PAGES.BETSLIP)}>
              {selectedOdds.length}
            </div>
          </div>
          <span className="bottom-menu__item-label">{t('common.word.betSlip')}</span>
        </div>

        <div
          id="myBetsBtn"
          className={`bottom-menu__item ${myBetsMenuItemActive() ? 'is-active' : ''}`}
          onClick={navigateToMyBets}
        >
          <div className="bottom-menu__item-iconWrap">
            <SVGComponent
              className="bottom-menu__item-icon"
              src={`${SVG_ICONS.utility}#${myBetsMenuItemActive() ? 'bet-active' : 'bet'}`}
            />
            <div className="bottom-menu__item-bets">{myBetsCount}</div>
          </div>

          <span className="bottom-menu__item-label">{t('common.word.myBets')}</span>
        </div>

        <div
          className={`bottom-menu__item ${resultsMenuItemActive() ? 'is-active' : ''}`}
          onClick={navigateTo.bind(null, PAGES.RESULTS)}
        >
          <SVGComponent
            className="bottom-menu__item-icon"
            src={`${getSVGIconUrl()}#${resultsMenuItemActive() ? 'results-active' : 'results'}`}
          />
          <span className="bottom-menu__item-label">{t('common.word.results')}</span>
        </div>
      </div>
    </>
  );
};

export default BottomMenu;
