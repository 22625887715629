import { useState, useEffect } from 'react';
import { timeLeft } from '../../../utils/common';

// eslint-disable-next-line import/prefer-default-export
export function useIsLive(liveStartDate, liveEndDate) {
  const [isLive, setIsLive] = useState(null);

  useEffect(() => {
    let interval = null;

    function setValueOfIsLive() {
      const liveStartLeft = timeLeft(liveStartDate);
      const liveLeft = timeLeft(liveEndDate);

      if (liveLeft > 0 && liveStartLeft <= 2) {
        setIsLive(true);
      } else {
        setIsLive(false);
      }
    }

    if (liveStartDate && liveEndDate) {
      setValueOfIsLive();
    } else {
      setIsLive(false);
    }

    if (liveStartDate && liveEndDate) {
      interval = setInterval(() => {
        setValueOfIsLive();
      }, 1000);
    } else {
      setIsLive(false);
    }

    return () => clearInterval(interval);
  }, [liveStartDate, liveEndDate]);

  return isLive;
}
