import React from 'react';
import { t } from 'reactjs-polyglot';
import { SVG_ICONS } from '../constants/globals';
import SVGComponent from './SVG/SVGComponent';

const ErrorFallback = () => {
  function reloadPage() {
    document.location.reload();
  }

  return (
    <>
      <div className="top-bar">
        <div className="top-bar__l" />
        <div className="top-bar__m">
          <span className="top-bar__logo" />
        </div>
        <div className="top-bar__r" />
      </div>
      <div className="scroll-area">
        <div className="error-banner">
          <div className="error-banner__img" />
          <div className="error-banner__title">{t('modal.error.oops')}</div>
          <div className="error-banner__description">{t('modal.error.contactSupport')}</div>
          <div className="btn btn--primary has-arrow" onClick={reloadPage}>
            <span>{t('modal.error.reloadPage')}</span> <SVGComponent className="btn__arrow" src={`${SVG_ICONS.utility}#arrow-right`} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorFallback;
