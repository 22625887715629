import * as Sentry from '@sentry/browser';

/**
 * Sort free bets by expiration date
 *
 * @param {Array} freeBets
 * @returns {Array}
 */

import { BET_TYPE } from '../constants/globals';

// eslint-disable-next-line import/prefer-default-export
export function sortFreeBets(freeBets) {
  freeBets.sort((a, b) => (new Date(a.expirationDate) < new Date(b.expirationDate) ? -1 : 1));
  return freeBets;
}

export function convertToCentsInt(num) {
  if (!num || Number.isNaN(num) || typeof num === 'string') {
    return 0;
  }
  return num * 100;
}

export function convertToCentsDecimal(num) {
  if (!num || Number.isNaN(num) || typeof num === 'string') {
    return 0;
  }
  return num / 100;
}

export function formatToDecimalFromCents(num, decimal = 2) {
  return (parseInt(num) / 100).toFixed(decimal);
}

export function formatToDecimal(num, decimal = 2) {
  return parseFloat(num).toFixed(decimal);
}

/**
 * apply Server Offset
 *
 * @function
 * @param {object} date   Date or Number
 * @returns {object} Date or Number
 */
export function applyServerOffset(date) {
  const offset = window.serverTimeOffset || 0;
  if (date instanceof Date) {
    return new Date(date.getTime() + offset);
  }
  return date + offset;
}

export function timeLeft(time) {
  return parseInt((new Date(time).getTime() - applyServerOffset(new Date())) / 1000);
}

function formatTwoDigits(number) {
  return (number < 10 ? '0' : '') + number;
}

export function formatTimer(seconds) {
  if (seconds === null || seconds <= 0) {
    return '00:00';
  }
  const min = formatTwoDigits(parseInt(seconds / 60));
  const sec = formatTwoDigits(parseInt(seconds % 60));

  return `${min}:${sec}`;
}

export function getBetType(selectedOdds) {
  if (!selectedOdds?.length) {
    return BET_TYPE.NO_BET;
  }

  if (selectedOdds.length === 1) {
    return BET_TYPE.SINGLE;
  }

  const numOfSelectionPerMatchMap = selectedOdds.reduce((acc, curr) => {
    const key = `${curr.leagueId}-${curr.matchId}`;
    acc[key] = acc[key] ? acc[key] + 1 : 1;
    return acc;
  }, {});

  const numberOfMatches = Object.keys(numOfSelectionPerMatchMap).length;

  if (numberOfMatches === 1) {
    return BET_TYPE.SINGLE;
  }

  const hasMultipleOddsFromSameMatch = Object.keys(numOfSelectionPerMatchMap).some(
    (key) => numOfSelectionPerMatchMap[key] > 1
  );
  const isSplitBet = numberOfMatches > 1 && hasMultipleOddsFromSameMatch;

  if (isSplitBet) {
    return BET_TYPE.SPLIT_BET;
  }

  return BET_TYPE.MULTIPLE;
}

export function formatStandings(teamDetail) {
  const formattedMatches = teamDetail.map((match) => {
    const [team, points, lastFiveStatus] = match.split('-');
    return {
      team,
      points,
      lastFiveStatus,
    };
  });

  return formattedMatches.reduce((acc, curr, index) => {
    acc[curr.team] = {
      ...curr,
      position: index + 1,
    };
    return acc;
  }, {});
}

export function formatLastMatchStatistics(statistics) {
  return statistics.map((round) => {
    const roundMatches = round.matches[0].matches.split('|');
    const results = round.matches[0].results.split('|');
    return roundMatches.map((m, index) => {
      const [homeTeam, awayTeam] = m.split('-');
      const [homeResult, awayResult] = results[index].split(':');
      return {
        homeTeam,
        homeResult: parseInt(homeResult),
        awayTeam,
        awayResult: parseInt(awayResult),
      };
    });
  });
}

/**
 * format time to hh:mm:ss
 *
 * @function
 * @param {Date} date
 * @returns {string} string
 */
export function formatTime(date) {
  const hours = `${date.getHours()}`.padStart(2, '0');
  const minutes = `${date.getMinutes()}`.padStart(2, '0');
  const seconds = `${date.getSeconds()}`.padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}

/**
 * Returns the expanded form of exponential number
 *
 * ex. 1e+21 becomes 1000000000000000000000
 *
 * Source: https://stackoverflow.com/questions/1685680/how-to-avoid-scientific-notation-for-large-numbers-in-javascript
 * @function
 * @param {number} x
 * @returns {string} expanded exponential number
 */
function toFixed(x) {
  let value = x;
  if (Math.abs(value) < 1.0) {
    const e = parseInt(value.toString().split('e-')[1]);
    if (e) {
      // eslint-disable-next-line no-restricted-properties
      value *= 10 ** (e - 1);
      value = `0.${new Array(e).join('0')}${value.toString().substring(2)}`;
    }
  } else {
    let e = parseInt(value.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      // eslint-disable-next-line no-restricted-properties
      value /= 10 ** e;
      value += new Array(e + 1).join('0');
    }
  }
  return value;
}

export function parseBigNums(bigNum) {
  if (!bigNum) {
    return '';
  }

  if (bigNum.toString().indexOf('e') === -1) {
    return bigNum;
  }

  const formattedBigNum = toFixed(bigNum);
  if (formattedBigNum.length > 10) {
    return `${formattedBigNum.slice(0, 10)}...`;
  }

  return `${formattedBigNum.slice(0, 10)}`;
}

/**
 * Function that reloads the page if component fails to lazy load because of chunk load error.
 * 
 * Chunk load error is thrown when users tried to access old code when the code has been updated.
 * 
 * It can also be caused by slow networks and the browsers fails to download the js code
 * 
 * credit to https://www.bitahoy.com/blog/post/fixing-chunk-load-errors-in-webpack-and-react-js
 * @param {Component} componentImport dynamic import of component
 * @returns {void} void
 */
export const lazyRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');
    componentImport()
      // eslint-disable-next-line consistent-return
      .then((component) => {
        window.sessionStorage.setItem(storageKey, 'false');
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, 'true');
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true');
          window.location.reload();
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });

/**
 * Wrapper function for the custom error logger library
 *
 * @param {object} error
 * @param {string} error.info short description on type of error
 * @param {string } error.errorMessage the actual error message
 * @param {object} error.args other arguments that can be passed
 * @returns {void} void
 */
export function logError({ info, errorMessage, ...args}) {
  Sentry.withScope(function callback(scope) {
    scope.setLevel('error');
    scope.setTransactionName(info);
  
    Sentry.captureException(new Error(errorMessage || info), { extra: { ...args }});
  });
}