import { createSlice } from '@reduxjs/toolkit';
import { LOADER_STATUS } from '../../../constants/globals';
import { formatLastMatchStatistics, formatStandings } from '../../../utils/common';

const initialState = {
  results: {},
  loadingStatus: LOADER_STATUS.IN_PROGRESS,
  leagueStatistics: {},
};

const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    loadLeagueResults(state) {
      state.loadingStatus = LOADER_STATUS.IN_PROGRESS;
    },
    loadLeagueResultsSuccess(state, action) {
      const formattedResults = action.payload.data.results.map((r) => {
        const matches = r.matches.split('|');
        const res = r.results.split('|');
        const matchesWithScores = matches.map((m, index) => {
          const [homeTeam, awayTeam] = m.split('-');
          const score = res[index].replace(':', '-');
          const [homeScore, awayScore] = res[index].split(':');

          return { key: m, homeTeam, awayTeam, score, homeScore, awayScore };
        });

        return {
          week: r.week,
          time: r.time,
          results: matchesWithScores,
        };
      });

      state.results = {
        ...state.results,
        [action.payload.data.leagueId]: {
          ...action.payload.data,
          results: formattedResults,
        },
      };
      state.loadingStatus = LOADER_STATUS.START;
    },
    loadLeagueResultsFail(state) {
      state.loadingStatus = LOADER_STATUS.START;
    },
    loadLeagueTableData(state) {
      state.loadingStatus = LOADER_STATUS.IN_PROGRESS;
    },
    loadLeagueTableDataFail(state) {
      state.loadingStatus = LOADER_STATUS.START;
    },
    loadLeagueTableDataSuccess(state, action) {
      state.loadingStatus = LOADER_STATUS.START;
      const leagueStanding = action.payload.data.standings.find((s) => s.competition === action.payload.leagueId);

      if (!leagueStanding) {
        return;
      }
      const teamDetail = leagueStanding.value.split('|');

      state.leagueStatistics = {
        ...state.leagueStatistics,
        [action.payload.leagueId]: {
          week: leagueStanding.week,
          standings: formatStandings(teamDetail),
          lastFiveMatches: formatLastMatchStatistics(action.payload.data.statistics),
        },
      };
    },
    clearResultsStats(state) {
      state.results = {};
      state.leagueStatistics = {};
    },
  },
});

export const resultsReducer = resultsSlice.reducer;

export const {
  loadLeagueResults,
  loadLeagueResultsSuccess,
  loadLeagueResultsFail,
  loadLeagueTableData,
  loadLeagueTableDataFail,
  loadLeagueTableDataSuccess,
  clearResultsStats,
} = resultsSlice.actions;
