import { t } from 'reactjs-polyglot';
import { BET_TYPE, BET_TYPE_VALUES, MATCH_RESULT_LABEL } from '../../constants/globals';
import { convertToCentsDecimal, formatToDecimal, parseBigNums } from '../../utils/common';
import { getCountryDateTime } from '../../utils/parser';
import { RESULT_STATUS } from './constants';

export function getType(type, numberOfSelections) {
  switch (type) {
    case BET_TYPE.SINGLE:
      return t('betTypeLabel.SINGLE');
    case BET_TYPE.COMBINATION:
      return t('betTypeLabel.COMBINATION');
    case BET_TYPE.SPLIT_BET:
      return t('betTypeLabel.SPLIT_BET');
    default: {
      const digitKey = `digits.${numberOfSelections - 1}`;
      return parseInt(numberOfSelections) > 3
        ? `${t(digitKey) || numberOfSelections} ${t('betslip.label.fold')}`
        : t(digitKey);
    }
  }
}

/**
 * format time to hh:mm:ss
 *
 * @function
 * @param {Date} date
 * @returns {string} string
 */
function formatTime(date) {
  if (date === null || date === undefined) {
    return '';
  }

  const { time, seconds } = getCountryDateTime(date.toISOString());
  return `${time}:${seconds}`;
}

export function formatBetInBetHistory(bet) {
  const betTypeMapping = Object.entries(BET_TYPE_VALUES).reduce((acc, curr) => {
    const [key, value] = curr;
    acc[value] = key;
    return acc;
  }, {});

  const [betType, selections] = bet.betdata.split('|');
  const betTypeKey = betTypeMapping[betType];
  const [matchDetails, odd] = bet.betdetails.split('|');
  const [minOdd, maxOdd] = odd.split('-');
  const matches = matchDetails.split('$').map((m) => {
    const [name] = m.split(':');
    return name;
  });

  const date = new Date(bet.placed);
  const paddedMonth = `${date.getMonth() + 1}`.padStart(2, '0');
  const paddedDate = `${date.getDate()}`.padStart(2, '0');
  const formattedDate = `${date.getFullYear()}/${paddedMonth}/${paddedDate}`;

  const numberOfSelections = selections.split('$').length;

  return {
    id: bet.id,
    betData: bet.betdata,
    betDetails: bet.betdetails,
    betType: getType(betTypeKey, numberOfSelections),
    matches,
    minOdd,
    maxOdd,
    status: bet.status,
    stake: convertToCentsDecimal(bet.stake),
    win: convertToCentsDecimal(bet.win),
    isBonus: bet.bonus,
    isCashout: bet.cashout,
    date: formattedDate,
    time: formatTime(date),
    gameType: bet.game,
    roundId: bet.round,
    potentialWin: convertToCentsDecimal(bet.potwin),
    winLabel: bet.win > 0 ? MATCH_RESULT_LABEL.win : MATCH_RESULT_LABEL.lose,
  };
}

export function getFormattedBets(bets) {
  return bets.map(formatBetInBetHistory);
}

export function getSelectionsFromBetDetails(bet, allMarketSelections, leagues) {
  // eslint-disable-next-line no-unused-vars
  const [matchDetails, odds, winString] = bet.betDetails.split('|');
  const [betType, matchMarketSelection, stakesString] = bet.betData.split('|');

  const winnings = winString?.split('&').map((w) => {
    // eslint-disable-next-line no-unused-vars
    const [matchId, win] = w.split(':');
    return win;
  });

  let stakes = [];
  if (Number(betType) === BET_TYPE_VALUES[BET_TYPE.SINGLE]) {
    stakes = stakesString
      .split('&')
      .filter((s) => !s.includes('T'))
      .map((s) => {
        // eslint-disable-next-line no-unused-vars
        const [combiTypeNumber, stakeValue] = s.split(':');
        return stakeValue;
      });
  }

  const formattedMatchDetails = matchDetails.split('$').map((d, index) => {
    const [matchName, odd, score, result] = d.split(':');
    return {
      matchName,
      odd,
      winning: winnings ? convertToCentsDecimal(Number(winnings[index])) : null,
      score,
      winStatus: parseInt(result) === RESULT_STATUS.win ? RESULT_STATUS.win : RESULT_STATUS.lose,
      winLabel: parseInt(result) === RESULT_STATUS.win ? MATCH_RESULT_LABEL.win : MATCH_RESULT_LABEL.lose,
    };
  });
  const selectionDetail = matchMarketSelection.split('$').map((m) => {
    // eslint-disable-next-line no-unused-vars
    const [leagueId, matchId, marketSelectionId] = m.split(':');
    const { fullName, winningScores } = allMarketSelections[marketSelectionId];

    const league = leagues.find((l) => l.id === Number(leagueId));

    return {
      leagueId,
      matchId,
      selectionName: fullName,
      winningScores,
      leagueFlag: league?.flag,
    };
  });

  return formattedMatchDetails.map((m, index) => ({
    ...m,
    ...selectionDetail[index],
    stake: stakes[index] ? convertToCentsDecimal(Number(stakes[index])) : null,
  }));
}

export function getCombiStakesFromBetDetails(bet) {
  // eslint-disable-next-line no-unused-vars
  const [matchDetails, odds, winningsString] = bet.betDetails.split('|');
  // eslint-disable-next-line no-unused-vars
  const [betType, marketSelection, stakesString] = bet.betData.split('|');

  const stakes = stakesString
    .split('&')
    .filter((s) => !s.includes('T'))
    .map((s) => {
      const [combiTypeNumber, stake] = s.split(':');
      return {
        combiType: t(`digits.${parseInt(combiTypeNumber) - 1}`),
        stake: convertToCentsDecimal(parseFloat(stake)),
      };
    });

  const winnings = winningsString ? winningsString.split('&') : [];
  return stakes.map((s, i) => {
    let win = 0;
    if (winnings.length) {
      // eslint-disable-next-line no-unused-vars
      const [combiTypeNumber, winVal] = winnings[i].split(':');
      win = winVal;
    }
    return {
      ...s,
      win: convertToCentsDecimal(parseFloat(win)),
    };
  });
}

export const getBetOdds = (bet) =>
  bet.maxOdd && bet.minOdd !== bet.maxOdd
    ? `${parseBigNums(formatToDecimal(bet.minOdd))} - ${parseBigNums(formatToDecimal(bet.maxOdd))}`
    : parseBigNums(formatToDecimal(bet.minOdd));
