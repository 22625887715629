/**
 * Parse number as currency. For example 9,999,999
 *
 * @function
 * @param {number} value
 * @returns {string}
 */

import produce from 'immer';
import { COUNTRY_OFFSET, COUNTRY_TIMEZONE } from '../constants/globals';
import { applyServerOffset } from './common';

// eslint-disable-next-line import/prefer-default-export
export const parseNum = (value) => value.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

/**
 * Parse string formatted as currency into integer. For example 9,999,999 to 9999999
 *
 * @function
 * @param {string} value
 * @returns {number}
 */
export const parseCommaSeparatedStringToInt = (value) => parseInt(value.split(',').join(''));

/**
 * Parse date in format DD/MM/YYYY, time in format hh:mm:ss with selected country time zone
 *
 * @param {Time} dateString
 * @example 2020-04-27T09:18:02Z
 * @returns {object} object with date, time, and seconds property
 */
export function getCountryDateTime(dateString) {
  if (dateString) {
    const timezone = COUNTRY_TIMEZONE[process.env.BUNDLE_SKIN];
    const offset = applyServerOffset(new Date()).getTimezoneOffset();
    const localDateTime = new Date(dateString.replace(/z/gi, '')).setMinutes(
      new Date(dateString).getMinutes() - offset
    );
    const dateTime = new Date(localDateTime).toLocaleString('en-GB', { timeZone: timezone });
    const dateTimeArr = dateTime.split(',');
    const date = dateTimeArr[0].split('/');
    const time = dateTimeArr[1].split(':');
    return {
      date: `${date[2]}/${date[1]}/${date[0]}`,
      time: `${time[0]}:${time[1]}`,
      seconds: time[2],
    };
  }
  return '';
}

/**
 * get javascript date object in country time from utc time
 *
 * @param {string} countryOffset
 * @param {Time} date
 * @example 2020-04-27T09:18:02Z
 * parsed to   "27/04/2020 09:18:02"
 * @returns {string} Parsed date and time
 */
export function getCountryDateFromUTC(countryOffset, date) {
  return new Date(date).setMinutes(new Date(date).getMinutes() + countryOffset);
}

/**
 * get javascript date object in nigeria time
 *
 * @param {string} country
 * @param {Time} date
 * @returns {string} Parsed date and time
 */
export function getCountryNewDate(country, date) {
  if (!country) {
    return '';
  }
  const offset = applyServerOffset(new Date()).getTimezoneOffset();
  const countryOffset = offset + COUNTRY_OFFSET[country].OFFSET;
  const countryDate = date ? new Date(date) : applyServerOffset(new Date());
  return new Date(
    Date.UTC(
      countryDate.getUTCFullYear(),
      countryDate.getUTCMonth(),
      countryDate.getUTCDate(),
      countryDate.getUTCHours(),
      countryDate.getUTCMinutes() + countryOffset,
      countryDate.getUTCSeconds()
    )
  );
}

/**
 * remapMarketsToSelectionWinningScores
 *
 * @param {Array} markets
 * @returns {object} Map (selection, WinningScores)
 */
export function remapMarketsToSelectionWinningScores(markets) {
  const result = {};

  for (let i = 0; i < markets.length; i += 1) {
    const market = markets[i];

    if (market.submarkets === undefined) {
      const selectionIds = Object.keys(market.selections);
      for (let j = 0; j < selectionIds.length; j += 1) {
        const selectionId = selectionIds[j];
        result[selectionId] = {
          winningScores: market.selections[selectionId].winningScores,
          alwaysWin: market.selections[selectionId].alwaysWin,
          alwaysLose: market.selections[selectionId].alwaysLose,
          name: market.selections[selectionId].name,
          fullName: market.selections[selectionId].fullName,
        };
      }
    } else {
      for (let j = 0; j < market.submarkets.length; j += 1) {
        const submarket = market.submarkets[j];
        const selectionIds = Object.keys(submarket.selections);
        for (let k = 0; k < selectionIds.length; k += 1) {
          const selectionId = selectionIds[k];
          result[selectionId] = {
            winningScores: submarket.selections[selectionId].winningScores,
            alwaysWin: submarket.selections[selectionId].alwaysWin,
            alwaysLose: submarket.selections[selectionId].alwaysLose,
            name: submarket.selections[selectionId].name,
            fullName: submarket.selections[selectionId].fullName,
          };
        }
      }
    }
  }

  return result;
}

/**
 * Updates information of markets of whether it is still enabled or not
 *
 * @param {Array} marketsParam
 * @example [{enabled: boolean, selections: {}, submarkets: {}}]
 * @param {Array} odds
 * @example ['101', '102']
 * @returns {Array} list of markets
 */
export function updateMarketAvailability(marketsParam, odds) {
  const oddsDictionary = odds.reduce((acc, curr, index) => {
    acc[curr] = index;
    return acc;
  }, {});

  return produce(marketsParam, (markets) => {
    markets.forEach((m) => {
      if (!m.submarkets) {
        m.enabled = Object.keys(m.selections).some((key) => oddsDictionary[key]);
        m.selections = Object.keys(m.selections)
          .map((key) => ({
            ...m.selections[key],
            index: oddsDictionary[key],
            key,
          }))
          .reduce((acc, curr) => {
            acc[curr.key] = curr;
            return acc;
          }, {});
      } else {
        m.submarkets.forEach((sm) => {
          // eslint-disable-next-line max-nested-callbacks
          sm.enabled = Object.keys(sm.selections).some((key) => oddsDictionary[key]);
          // eslint-disable-next-line max-nested-callbacks
          sm.selections = Object.keys(sm.selections)
            // eslint-disable-next-line max-nested-callbacks
            .map((key) => ({
              ...sm.selections[key],
              index: oddsDictionary[key],
              key,
            }))
            // eslint-disable-next-line max-nested-callbacks
            .reduce((acc, curr) => {
              acc[curr.key] = curr;
              return acc;
            }, {});
          m.enabled = m.enabled || sm.enabled;
        });
      }
    });
  });
}
