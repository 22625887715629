import React, { useEffect, useRef, useState } from 'react';
import { useTranslationContext } from 'reactjs-polyglot';
import { useDispatch } from 'react-redux';
import { MENUS, SVG_ICONS } from '../../constants/globals';
import { toggleMenu } from '../../store/reducer';
import { formatShortBonusTime } from '../../utils/bonus';
import { convertToCentsDecimal } from '../../utils/common';
import { parseNum } from '../../utils/parser';
import SVGComponent from '../SVG/SVGComponent';

const LoyaltyMenu = ({ freeBets, time, config, userBonus }) => {
  const dispatch = useDispatch();
  const { t } = useTranslationContext();
  const previousBonusId = useRef(null);

  const [isLevelUp, setIsLevelUp] = useState(false);

  useEffect(() => {
    let timerId = null;
    const millisecondsBeforeHidingBonusLevelUp = 5000;

    if (
      userBonus?.userBonusId &&
      previousBonusId.current !== null &&
      previousBonusId.current !== userBonus?.userBonusId &&
      userBonus?.bonusLevelId > 1
    ) {
      setIsLevelUp(true);
      timerId = setTimeout(() => {
        setIsLevelUp(false);
      }, [millisecondsBeforeHidingBonusLevelUp]);
    }

    if (userBonus?.userBonusId !== null && userBonus?.userBonusId !== undefined) {
      previousBonusId.current = userBonus.userBonusId;
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [userBonus?.userBonusId, userBonus?.bonusLevelId]);

  function toggleBonusMenu() {
    dispatch(toggleMenu({ menu: MENUS.BONUS }));
  }

  function getLeagueBonusClass() {
    if (userBonus?.bonusName) {
      return `league-bar__bonus--${userBonus.bonusName.toLowerCase()}`;
    }
    return '';
  }

  return (
    <div className="league-bar__r">
      <div className={`league-bar__bonus ${getLeagueBonusClass()}`}>
        <div className="league-bar__bonusInfo">
          <div className="league-bar__bonusRow">
            <div className="league-bar__bonusAmount">
              {parseNum(convertToCentsDecimal(userBonus?.bonusAmount || 0))} {config?.currencySymbol}
            </div>
            <div className="league-bar__bonusGift ml-3">
              <SVGComponent className="sm-icon" src={`img/${SVG_ICONS.utility}#freebet`} />
              <div className="league-bar__bonusFreebet">{freeBets?.length}</div>
            </div>
          </div>
          <div className="league-bar__bonusRow">
            <div className="league-bar__bonusDays">
              {userBonus?.active ? formatShortBonusTime(time) : userBonus?.maxBonusDuration}
            </div>
            <div className="league-bar__bonusPercentage ml-3">{userBonus?.completionPercentage || 0}%</div>
          </div>
        </div>
        <div className="league-bar__bonusDropdown" onClick={toggleBonusMenu}>
          <SVGComponent className="sm-icon" src={`img/${SVG_ICONS.utility}#icon__arrow-down`} />
        </div>
        {isLevelUp ? (
          <div className="league-bar__bonusLevel is-completed">
            <div className="league-bar__bonusLevelContent">
              <div className="league-bar__bonusLevelTxt">{t('loyaltyMenu.label.bonusLevel')}</div>
              <div className="league-bar__bonusLevelStatus">{t('loyaltyMenu.label.completed')}</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LoyaltyMenu;
