import { createSlice } from '@reduxjs/toolkit';
import { LOADER_STATUS } from '../../../constants/globals';

const initialState = {
  savingStatus: LOADER_STATUS.START,
  error: null,
  betRequestData: null,
};

const betslipSlice = createSlice({
  name: 'betslip',
  initialState,
  reducers: {
    initBetslipState(state) {
      state.savingStatus = LOADER_STATUS.START;
      state.error = null;
    },
    placeBet(state) {
      state.savingStatus = LOADER_STATUS.IN_PROGRESS;
      state.error = null;
      state.betRequestData = null;
    },
    placeBetSuccess(state) {
      state.savingStatus = LOADER_STATUS.SUCCESS;
    },
    placeBetFail(state, action) {
      state.savingStatus = LOADER_STATUS.ERROR;
      state.error = action.payload;
    },
    updateBetslipSaveState(state, action) {
      state.savingStatus = action.payload;
    },
    updateBetslipError(state, action) {
      state.error = action.payload;
      state.savingStatus = action.payload ? LOADER_STATUS.ERROR : LOADER_STATUS.START;
    },
    setTempBetRequestData(state, action) {
      state.betRequestData = action.payload;
    },
  },
});

export const betslipReducer = betslipSlice.reducer;

export const {
  initBetslipState,
  placeBet,
  placeBetFail,
  placeBetSuccess,
  updateBetslipSaveState,
  updateBetslipError,
  setTempBetRequestData,
} = betslipSlice.actions;
