import { createSelector } from '@reduxjs/toolkit';
import { BET_TYPE } from '../../../constants/globals';
import { getAllMarketSelections, getRoundData } from '../../../store/selectors';
import { applyServerOffset, convertToCentsDecimal, getBetType } from '../../../utils/common';
import { getUserFreeBets } from '../../auth/store/selectors';

export const getBetslipState = (state) => state.betslip;
export const getOddsState = (state) => state.odds;

export const getBetslipSelections = 
  createSelector([getOddsState, getRoundData, getAllMarketSelections], (oddsState, roundData, allMarkets) => {
    const selectedOdds = oddsState?.selectedOdds;

    if (!selectedOdds?.length) {
      return [];
    }

    const leagueData = {};

    selectedOdds.forEach((odd) => {
      if (leagueData[odd.leagueId]) {
        return;
      }

      const leagueIndex = roundData.competitions.findIndex((x) => x === odd.leagueId);
      const matches = roundData.matches[leagueIndex].split('|');
      const odds = roundData.odds[leagueIndex].split('|').map((oddsForMatch) => oddsForMatch.split('-'));

      const marketSelectionIndexMapping = roundData.selections[leagueIndex]
        .split('-')
        .reduce((acc, curr, index) => {
          acc[curr] = { index };
          return acc;
        }, {});

      leagueData[odd.leagueId] = {
        matches,
        odds,
        marketSelectionIndexMapping,
      };
    });

    return selectedOdds.map((odd) => {
      const { matches, odds, marketSelectionIndexMapping } = leagueData[odd.leagueId];
      const { index: selectionIndex } = marketSelectionIndexMapping[odd.selectionId];

      return {
        key: `${odd.leagueId}-${odd.matchId}-${odd.selectionId}`,
        leagueId: odd.leagueId,
        selectionIndex: odd.selectionIndex,
        matchId: odd.matchId,
        name: matches[odd.matchId],
        fullName: allMarkets[odd.selectionId].fullName,
        oddLabel: allMarkets[odd.selectionId].fullName,
        oddValue: odds[odd.matchId][selectionIndex],
        marketSelectionId: odd.selectionId,
        winningScores: allMarkets[odd.selectionId].winningScores,
        marketSelectionName: allMarkets[odd.selectionId].name,
      };
    });
  });

export const getBetTypeForSelections = (state) => {
  const selectedOdds = state.odds?.selectedOdds;
  return getBetType(selectedOdds);
};

export const getIsFreeBetEnabled = (state) => {
  const selectedOdds = state.odds?.selectedOdds;
  const betType = getBetType(selectedOdds);
  const hasFreeBets = state.auth?.user?.freeBets?.length > 0;
  const hasSelectedOdds = selectedOdds?.length > 0;

  const numOfSelectionPerMatchMap = selectedOdds.reduce((acc, curr) => {
    const key = `${curr.leagueId}-${curr.matchId}`;
    acc[key] = acc[key] ? acc[key] + 1 : 1;
    return acc;
  }, {});

  const hasMultipleOddsFromSameMatch = Object.keys(numOfSelectionPerMatchMap).some(
    (key) => numOfSelectionPerMatchMap[key] > 1
  );

  return hasSelectedOdds && hasFreeBets && betType !== BET_TYPE.SPLIT_BET && !hasMultipleOddsFromSameMatch;
};

export const getHasFreeBets = (state) => state.auth?.user?.freeBets?.length > 0;

export const getFreeBetStakeValues = createSelector([getUserFreeBets], (userFreeBets) => {
// remove expired bets
  const freeBets = (userFreeBets || []).filter(
    (bet) => new Date(bet.expirationDate).getTime() > applyServerOffset(new Date()).getTime()
  );
  const values = freeBets.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

  return Object.keys(values).map((v) => ({
    id: values[v].id,
    value: convertToCentsDecimal(parseFloat(values[v].stake)),
  }));
});
