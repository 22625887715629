import { put, call, takeEvery, select } from 'redux-saga/effects';
import { API_URLS, GAME_MODE, MODALS } from '../../../constants/globals';
import { setActiveModal } from '../../../store/reducer';
import { getGameMode, getInitData, getSelectedOdds } from '../../../store/selectors';
import { requestGet } from '../../../utils/request';
import {
  loadLeagueStatistics,
  loadLeagueStatisticsFail,
  loadLeagueStatisticsSuccess,
  toggleSelectedOdd,
  toggleSelectedOddSaga,
} from './slice';

function* getLeagueStatistics(action) {
  try {
    const gameMode = yield select(getGameMode);
    let url =
      gameMode === GAME_MODE.TURBO
        ? API_URLS.LEAGUE_STATISTICS_TURBO
        : API_URLS.LEAGUE_STATISTICS.replace('$gameId', gameMode);

    url = url.replace('$leagueId', action.payload.leagueId);

    const response = yield call(requestGet, url);

    if (response.status === -1) {
      yield put(loadLeagueStatisticsFail(response.error.message));
      return;
    }

    yield put(loadLeagueStatisticsSuccess({ leagueId: action.payload.leagueId, data: response.data }));
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* toggleOdd(action) {
  const { leagueId, matchId, selectionId, selectionIndex, odd, winningScores, name } = action.payload;
  const matchKey = `${leagueId}-${matchId}-${selectionId}`;

  const initData = yield select(getInitData);
  const selectedOdds = yield select(getSelectedOdds);

  const isSelected = selectedOdds.some((x) => x.key === matchKey);

  if (!isSelected && selectedOdds.length + 1 > initData?.numbets) {
    yield put(setActiveModal({ modal: MODALS.MAX_SELECTIONS }));
    return;
  }

  yield put(
    toggleSelectedOdd({
      leagueId,
      matchId,
      selectionId,
      selectionIndex,
      odd,
      winningScores,
      name,
    })
  );
}

export default function* oddsSaga() {
  yield takeEvery(loadLeagueStatistics, getLeagueStatistics);
  yield takeEvery(toggleSelectedOddSaga, toggleOdd);
}
