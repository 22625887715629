import { delay, put, select, takeLeading, call, takeEvery } from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import {
  setInitData,
  setRoundData,
  setExternalIFrameUrl,
  goToAccountDeposit,
  goToMyAccount,
  goToCashier,
  loadRoundBets,
  setActiveModal,
  loadRound,
  navigateTo,
  addPlacedBetBulk,
  kickOff,
  kickOffSuccess,
  kickOffFail,
  setLastBetRoundTurbo,
  scheduleBetCount,
  endLiveSaga,
  removeScheduledBetCount,
  setMarkets,
  setLeagueId,
  setEnabledLeagues,
  setCashoutParams,
  setCashoutSlotClearance,
  setTurboMatchLength,
} from './reducer';
import {
  getGameMode,
  getLastBetRoundTurbo,
  getAllPlacedBets,
  getRoundData,
  getSelectedLeague,
  getWinningScoresMap,
  getAllMarkets,
  get5Markets,
  getAllLeagues,
  getCurrentRoute,
} from './selectors';
import {
  API_URLS,
  ERRORS,
  GAME_MODE,
  LOADER_STATUS,
  MARKET_IDS,
  MODALS,
  PAGES,
  PAGES_NOT_TO_REDIRECT_TO_LIVE,
  TURBO_LIVE_OFFSET_SECONDS,
} from '../constants/globals';
import { requestGet } from '../utils/request';
import { getUserBalance } from '../modules/auth/store/slice';
import { applyServerOffset, timeLeft } from '../utils/common';
import {
  clearOddsLeagueStatistics,
  loadLeagueStatistics,
  removeAllOddSelections,
  setOddsLoaderStatus,
  setSelectedMarketId,
} from '../modules/odds/store/slice';
import { getLeagueStatistics } from '../modules/odds/store/selectors';
import { getIsAuthenticated } from '../modules/auth/store/selectors';
import { getMarketsFirstSubmarketId } from '../utils/odds';
import { updateMarketAvailability } from '../utils/parser';
import { getBetHistoryHasPending } from '../modules/bet-history/store/selectors';
import { reloadBetHistory, bulkBetCashoutSuccess } from '../modules/bet-history/store/slice';

const FETCH_INIT_DATA = 'SAGA@@FETCH_INIT_DATA';
export const fetchInitData = createAction(FETCH_INIT_DATA);
const APP_STAY_AWAKE = 'SAGA@@APP_STAY_AWAKE';
export const appStayAwake = createAction(APP_STAY_AWAKE);

/**
 *  When the browser tab becomes inactive, the countdown timers are stopped and as a result,
 * the round data gets stale. This saga makes sure that the round data is always up to date and countdown
 * timers are always running.
 *
 * @function
 * @returns {void}
 */
function* appStayAwakeSaga() {
  while (true) {
    const roundData = yield select(getRoundData);
    if (roundData && roundData.endDate && new Date(roundData.endDate) < applyServerOffset(new Date())) {
      yield put(loadRound());
    }
    yield delay(10000);
  }
}

function* setUpdatedMarket() {
  const roundData = yield select(getRoundData);
  const markets = yield select(getAllMarkets);
  const league = yield select(getSelectedLeague);

  const leaguedCompetitionIndex = roundData.competitions.findIndex((c) => c === league?.id);

  if (leaguedCompetitionIndex < 0) {
    return;
  }

  const odds = roundData.selections[leaguedCompetitionIndex].split('-');
  const updatedMarkets = updateMarketAvailability(markets, odds);
  yield put(setMarkets({ markets: updatedMarkets }));

  const shownMarkets = yield select(get5Markets);

  if (!shownMarkets || shownMarkets.length === 0) {
    return;
  }
  const [market] = shownMarkets;

  yield put(
    setSelectedMarketId({
      selectedMarketId: market.id,
      selectedSubmarketId: getMarketsFirstSubmarketId(market),
    })
  );
}

function* updateLeagues() {
  const selectedLeague = yield select(getSelectedLeague);
  const roundData = yield select(getRoundData);
  const competitions = roundData ? roundData.competitions : [];
  const allLeagues = yield select(getAllLeagues);
  const roundDataCompetitionIdsDictionary = competitions.reduce((acc, leagueId) => {
    acc[leagueId] = leagueId;
    return acc;
  }, {});

  const roundDataCompetitionsClone = [...competitions];
  roundDataCompetitionsClone.sort((a, b) => a - b);

  if (!selectedLeague) {
    // this sets default selected league id
    yield put(setLeagueId(roundDataCompetitionsClone[0]));
  }

  if (!allLeagues) {
    return;
  }

  const updatedLeagues = allLeagues.filter((l) => roundDataCompetitionIdsDictionary[l.id]);
  updatedLeagues.sort((a, b) => a.id - b.id);
  yield put(setEnabledLeagues({ leagues: updatedLeagues }));
}

function* kickOffTurboRound() {
  try {
    yield put(setOddsLoaderStatus({ status: LOADER_STATUS.IN_PROGRESS }));
    yield put(removeAllOddSelections());
    const roundData = yield select(getRoundData);
    const shownMarkets = yield select(get5Markets);
    const [market] = shownMarkets;

    const roundResultsResponse = yield call(requestGet, `${API_URLS.TURBO}/${roundData.roundId}/close`, true);
    const roundResponse = yield call(requestGet, `${API_URLS.TURBO}`, true);

    if (roundResultsResponse.status === -1 || roundResponse.status === -1) {
      throw new Error(roundResultsResponse.error.message);
    }

    yield put(setRoundData({ roundData: roundResponse.data, roundResults: roundResultsResponse.data }));
    yield put(setTurboMatchLength(roundResultsResponse.data.turboMatchLength));
    yield put(setLastBetRoundTurbo({ roundId: null }));
    yield updateLeagues();
    yield put(kickOffSuccess());
    yield put(setOddsLoaderStatus({ status: LOADER_STATUS.START }));
    yield put(
      setSelectedMarketId({
        selectedMarketId: market.id,
        selectedSubmarketId: getMarketsFirstSubmarketId(market),
      })
    );

    const currentRoute = yield select(getCurrentRoute);
    const pages = [PAGES.CASHIER, PAGES.MY_ACCOUNT];
    const shouldRedirectToLive = pages.every((p) => p !== currentRoute);

    if (shouldRedirectToLive) {
      yield put(navigateTo({ page: PAGES.LIVE }));
    }

    const endDate = new Date(roundResultsResponse.data.liveEndDate);
    endDate.setSeconds(endDate.getSeconds() + TURBO_LIVE_OFFSET_SECONDS);

    yield put(
      scheduleBetCount({
        roundId: roundResultsResponse.data.roundId,
        time: new Date(roundResultsResponse.data.liveEndDate).toISOString(),
        gameType: GAME_MODE.TURBO,
      })
    );
  } catch (error) {
    yield put(kickOffFail());
    yield put(
      setRoundData({
        roundData: null,
        roundResults: null,
      })
    );
    yield put(setOddsLoaderStatus({ status: LOADER_STATUS.START }));
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* fetchClassicRound() {
  let repeat = true;
  let numberOfRetries = 0;
  const gameMode = yield select(getGameMode);
  while (repeat && numberOfRetries < 5) {
    repeat = false;
    const url = API_URLS.ROUND.replace('$gameId', gameMode);
    const response = yield call(requestGet, url, true);

    if (response.status === -1) {
      if (response.error.code === ERRORS.RESULTS_NOT_READY || response.error.code === ERRORS.NEW_ROUND_NOT_READY) {
        repeat = true;
        numberOfRetries += 1;
        yield delay(2000);
        return;
      }

      throw new Error(response.error.message);
    }

    yield put(
      setRoundData({
        roundData: response.data.round,
        roundResults: response.data.roundResults,
      })
    );

    yield setUpdatedMarket();
    yield updateLeagues();

    if (gameMode === GAME_MODE.CASH_OUT) {
      yield put(
        setCashoutParams({
          cashoutMultiplier: response.data.roundResults.cashoutMultiplier,
          cashoutSlotDuration: response.data.roundResults.cashoutSlotDuration,
          cashoutNumberOfSlots: response.data.roundResults.cashoutNumberOfSlots,
        })
      );

      // offset seconds needed. In the live page, a modal is shown after live.
      // If last cashout is immediately cleared, the modal will not show correct data
      const offsetSeconds = 10;

      const endDate = new Date(response.data.roundResults.liveEndDate);
      endDate.setSeconds(endDate.getSeconds() + offsetSeconds);

      yield put(
        setCashoutSlotClearance({
          roundId: response.data.roundResults.roundId,
          endDate,
        })
      );
    }

    const liveStartLeft = timeLeft(response.data.roundResults?.liveStartDate);
    const liveLeft = timeLeft(response.data.roundResults?.liveEndDate);

    const isLive = liveLeft > 0 && liveStartLeft <= 2;

    if (isLive) {
      yield put(
        scheduleBetCount({
          roundId: response.data.roundResults.roundId,
          time: new Date(response.data.roundResults?.liveEndDate).toISOString(),
          gameType: GAME_MODE.CASH_OUT,
        })
      );
    }

    const currentRoute = yield select(getCurrentRoute);
    const shouldRedirectToLive = PAGES_NOT_TO_REDIRECT_TO_LIVE.every((p) => p !== currentRoute);

    if (isLive && shouldRedirectToLive) {
      yield put(navigateTo({ page: PAGES.LIVE }));
    }
  }
}

function* fetchTurboRound() {
  let repeat = true;
  let numberOfRetries = 0;
  while (repeat && numberOfRetries < 5) {
    repeat = false;
    const lastBetRoundTurbo = yield select(getLastBetRoundTurbo);

    const response = yield call(requestGet, API_URLS.TURBO, true);
    if (lastBetRoundTurbo && lastBetRoundTurbo !== response.data.roundId) {
      yield call(requestGet, `${API_URLS.TURBO}/${lastBetRoundTurbo}/close`, true);
      yield put(setLastBetRoundTurbo({ roundId: null }));
    }

    if (response.status === -1) {
      if (response.error.code === ERRORS.RESULTS_NOT_READY || response.error.code === ERRORS.NEW_ROUND_NOT_READY) {
        repeat = true;
        numberOfRetries += 1;
        yield delay(2000);
        return;
      }

      throw new Error(response.error.message);
    }

    yield put(
      setRoundData({
        roundData: response.data,
        roundResults: null,
      })
    );

    yield setUpdatedMarket();
    yield updateLeagues();

    yield put(setOddsLoaderStatus({ status: LOADER_STATUS.START }));
    const liveStartLeft = timeLeft(response.data.roundResults?.liveStartDate);
    const liveLeft = timeLeft(response.data.roundResults?.liveEndDate);

    const isLive = liveLeft > 0 && liveStartLeft <= 2;
    const currentRoute = yield select(getCurrentRoute);
    const shouldRedirectToLive = PAGES_NOT_TO_REDIRECT_TO_LIVE.every((p) => p !== currentRoute);

    if (isLive && shouldRedirectToLive) {
      yield put(navigateTo({ page: PAGES.LIVE }));
    }

    yield put(setOddsLoaderStatus({ status: LOADER_STATUS.START }));
  }
}

function* fetchRound() {
  try {
    yield put(removeAllOddSelections());
    yield put(setOddsLoaderStatus({ status: LOADER_STATUS.IN_PROGRESS }));
    const gameMode = yield select(getGameMode);
    const shownMarkets = yield select(get5Markets);

    if (gameMode === GAME_MODE.CLASSIC || gameMode === GAME_MODE.CASH_OUT) {
      yield fetchClassicRound();
    }
    if (gameMode === GAME_MODE.TURBO) {
      yield fetchTurboRound();
    }

    if (shownMarkets && shownMarkets[0]) {
      yield put(
        setSelectedMarketId({
          selectedMarketId: shownMarkets[0].id,
          selectedSubmarketId: getMarketsFirstSubmarketId(shownMarkets[0]),
        })
      );
    }
    yield put(setOddsLoaderStatus({ status: LOADER_STATUS.START }));
  } catch (error) {
    yield put(
      setRoundData({
        roundData: null,
        roundResults: null,
      })
    );
    yield put(setOddsLoaderStatus({ status: LOADER_STATUS.START }));
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* initDataFetcher() {
  try {
    const response = yield call(requestGet, API_URLS.INIT, true);
    if (response.status === -1) {
      throw new Error(response.error.message);
    } else {
      const localDate = applyServerOffset(new Date());
      const serverDate = new Date(response.data.serverTime);
      window.serverTimeOffset = serverDate - localDate;

      const marketIdOrder = [
        MARKET_IDS.MARKET_1X2,
        MARKET_IDS.MARKET_OVER_UNDER,
        MARKET_IDS.MARKET_HOME_OVER_UNDER,
        MARKET_IDS.MARKET_AWAY_OVER_UNDER,
        MARKET_IDS.MARKET_CORRECT_SCORE,
        MARKET_IDS.MARKET_GOAL_NO_GOAL,
        MARKET_IDS.MARKET_DOUBLE_CHANCE,
        MARKET_IDS.MARKET_1X2_OVER_UNDER,
        MARKET_IDS.MARKET_MULTIGOAL,
        MARKET_IDS.MARKET_MULTIGOAL_HOME,
        MARKET_IDS.MARKET_MULTIGOAL_AWAY,
        MARKET_IDS.MARKET_DC_OVER_UNDER,
      ];

      const { markets } = response.data;

      const marketMap = {};
      for (let i = 0; i < markets.length; i += 1) {
        const market = markets[i];
        marketMap[market.id] = market;

        // sort submarkets for 1X2 + Over/Under
        if (market.id === MARKET_IDS.MARKET_1X2_OVER_UNDER) {
          const mId = market.id;
          marketMap[mId].submarkets = marketMap[mId].submarkets.sort((a, b) => a.id - b.id);
        }
      }

      const reorderedMarkets = [];
      for (let i = 0; i < marketIdOrder.length; i += 1) {
        const marketId = marketIdOrder[i];
        if (marketMap[marketId]) {
          reorderedMarkets.push(marketMap[marketId]);
        }
      }
      response.data.markets = reorderedMarkets;

      yield put(setInitData({ initData: response.data }));
      yield updateLeagues();
    }
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* accountDepostiSaga() {
  try {
    const response = yield call(requestGet, API_URLS.ACCOUNT_DEPOSIT, true);

    if (response.status === 1) {
      yield put(setExternalIFrameUrl({ selectedExternalIframeUrl: response.data.url }));
    }
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* goToMyAccountSaga() {
  try {
    const response = yield call(requestGet, API_URLS.MY_ACCOUNT, true);

    if (response.status === 1) {
      yield put(setExternalIFrameUrl({ selectedExternalIframeUrl: response.data.url }));
    }
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* goToCashierSaga() {
  try {
    const response = yield call(requestGet, API_URLS.CASHIER, true);

    if (response.status === 1) {
      yield put(setExternalIFrameUrl({ selectedExternalIframeUrl: response.data.url }));
    }
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* openBets() {
  try {
    const response = yield call(requestGet, `${API_URLS.OPEN_BETS}`, true);

    if (response.status !== -1) {
      const clasicBets = response.data.bettingRoundClassic;
      const clasicBetsLive = response.data.liveRoundClassic;
      const cashoutBets = response.data.bettingRoundCashout;
      const cashoutBetsLive = response.data.liveRoundCashout;
      const tubroBets = response.data.bettingRoundTurbo;
      const tubroBetsLive = response.data.liveRoundTurbo;

      if (clasicBets > 0 || clasicBetsLive > 0) {
        const url = API_URLS.ROUND.replace('$gameId', GAME_MODE.CLASSIC);
        const classicRoundResponse = yield call(requestGet, url, true);

        if (clasicBets > 0) {
          yield put(
            scheduleBetCount({
              roundId: classicRoundResponse.data.round.roundId,
              gameType: GAME_MODE.CLASSIC,
              time: new Date(Date.parse(classicRoundResponse.data.round.endDate)).toISOString(),
            })
          );
        }
        if (clasicBetsLive > 0) {
          yield put(
            scheduleBetCount({
              roundId: classicRoundResponse.data.roundResults.roundId,
              gameType: GAME_MODE.CLASSIC,
              time: new Date(Date.parse(classicRoundResponse.data.roundResults.liveEndDate)).toISOString(),
            })
          );
        }
      }

      if (cashoutBets > 0 || cashoutBetsLive > 0) {
        const url = API_URLS.ROUND.replace('$gameId', GAME_MODE.CASH_OUT);
        const cashoutRoundResponse = yield call(requestGet, url, true);

        if (cashoutBets > 0) {
          yield put(
            scheduleBetCount({
              roundId: cashoutRoundResponse.data.round.roundId,
              gameType: GAME_MODE.CASH_OUT,
              time: new Date(Date.parse(cashoutRoundResponse.data.round.endDate)).toISOString(),
            })
          );
        }
        if (cashoutBetsLive > 0) {
          yield put(
            scheduleBetCount({
              roundId: cashoutRoundResponse.data.roundResults.roundId,
              gameType: GAME_MODE.CASH_OUT,
              time: new Date(Date.parse(cashoutRoundResponse.data.roundResults.liveEndDate)).toISOString(),
            })
          );
        }
      }

      if (tubroBets > 0 || tubroBetsLive > 0) {
        const turboRoundResponse = yield call(requestGet, API_URLS.TURBO, true);
        const { endDate } = turboRoundResponse.data;
        if (endDate) {
          yield put(
            scheduleBetCount({
              roundId: turboRoundResponse.data.roundId,
              gameType: GAME_MODE.TURBO,
              time: new Date(Date.parse(endDate)).toISOString(),
            })
          );
        }
      }
    }
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* roundBets() {
  try {
    const response = yield call(requestGet, API_URLS.ROUND_BETS);

    if (response.status === 1) {
      let winningScoresMap;

      while (!winningScoresMap) {
        winningScoresMap = yield select(getWinningScoresMap);
        yield delay(100);
      }

      const roundKeys = Object.keys(response.data);
      for (let i = 0; i < roundKeys.length; i += 1) {
        const roundKey = roundKeys[i];
        const { roundId, coupons } = response.data[roundKey];
        if (roundId > 0 && coupons.length > 0) {
          const enrichedCoupons = coupons.map((c) => {
            const { betOdds } = c;
            const [min, max] = betOdds.split('-');
            const couponOdds = {
              min,
              max: max || min,
            };
            return {
              ...c,
              couponId: c.betId,
              couponOdds,
              selections: c.selections.map((s) => {
                const [leagueId, matchId, selectionId, odd] = s.split(':');
                const ws = winningScoresMap[selectionId].winningScores.replace(/:/g, '-');
                const { alwaysWin, alwaysLose, name, fullName } = winningScoresMap[selectionId];
                return {
                  leagueId,
                  matchId: parseInt(matchId) - 1,
                  selectionId,
                  odd,
                  winningScores: ws,
                  alwaysWin,
                  alwaysLose,
                  name,
                  fullName,
                };
              }),
            };
          });
          yield put(addPlacedBetBulk({ roundId, coupons: enrichedCoupons }));
          yield put(
            bulkBetCashoutSuccess({
              roundId,
              coupons: response.data.liveRoundCashout?.coupons,
            })
          );
        }
      }
    }
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* finishLive(action) {
  const isOddsPage = action.payload.page?.includes(PAGES.ODDS);
  const isBetHistoryPage = action.payload.page?.includes(PAGES.MY_BETS);
  const isBetDetailPage = action.payload.page?.includes(PAGES.BET_DETAIL);

  const league = yield select(getSelectedLeague);
  const leagueStatistics = yield select(getLeagueStatistics);
  const isLoggedIn = yield select(getIsAuthenticated);
  const placedBets = yield select(getAllPlacedBets);
  const hasPendingBets = yield select(getBetHistoryHasPending);

  if (isOddsPage && leagueStatistics) {
    yield put(loadLeagueStatistics({ leagueId: league.id, clearOthers: true }));
  } else {
    yield put(clearOddsLeagueStatistics());
  }

  if ((isBetHistoryPage || isBetDetailPage) && hasPendingBets) {
    yield put(reloadBetHistory());
  }

  yield put(removeScheduledBetCount({ roundId: action.payload.roundId }));

  if (isLoggedIn && placedBets[action.payload.roundId]) {
    yield put(getUserBalance());
  }
}

/**
 * @async
 * @returns {void}
 */
export default function* appSaga() {
  yield takeLeading(appStayAwake, appStayAwakeSaga);
  yield takeLeading(fetchInitData, initDataFetcher);
  yield takeEvery(goToAccountDeposit, accountDepostiSaga);
  yield takeEvery(goToMyAccount, goToMyAccountSaga);
  yield takeEvery(goToCashier, goToCashierSaga);
  yield takeEvery(loadRoundBets, openBets);
  yield takeEvery(loadRoundBets, roundBets);
  yield takeEvery(loadRound, fetchRound);
  yield takeEvery(kickOff, kickOffTurboRound);
  yield takeEvery(endLiveSaga, finishLive);
}
