/**
 * @external 'node_modules/olaf'
 * @see {@link https://gitlab.com/providus.mu/fe-utils/olaf}
 */
import { createOlafApplication } from 'olaf';
import React, { lazy } from 'react';
import { initi18n } from 'reactjs-polyglot';
import { Route, Routes, Navigate } from 'react-router-dom';
import LS_CONSTANTS from 'build-utils-react/config/constants';
import App from './App';
import { LOCALE, PAGES } from './constants/globals';
import appReducer from './store/reducer';
import authReducer from './modules/auth/store/slice';
import { oddsReducer } from './modules/odds/store/slice';
import appSaga from './store/sagas';
import authSaga from './modules/auth/store/saga';
import { betslipReducer } from './modules/betslip/store/slice';
import betslipSaga from './modules/betslip/store/sagas';
import oddsSaga from './modules/odds/store/sagas';
import ExternaliFrame from './components/ExternaliFrame';
import { betHistoryReducer } from './modules/bet-history/store/slice';
import betHistorySaga from './modules/bet-history/store/sagas';
import Root from './Root';
import { resultsReducer } from './modules/results/store/slice';
import resultsSaga from './modules/results/store/sagas';
import englishTranslations from './translations/en';
import spanishTranslations from './translations/es';
import { lazyRetry } from './utils/common';

const LobyPage = lazy(() => lazyRetry(() => import('./modules/lobby/components')));
const OddsPage = lazy(() => lazyRetry(() => import('./modules/odds/components')));
const LivePage = lazy(() => lazyRetry(() => import('./modules/live/components')));
const BetslipPage = lazy(() => lazyRetry(() => import('./modules/betslip/components')));
const LoginPage = lazy(() => lazyRetry(() => import('./modules/auth/components')));
const BetHistoryPage = lazy(() => lazyRetry(() => import('./modules/bet-history/components')));
const ResultsPage = lazy(() => lazyRetry(() => import('./modules/results/components')));

const locale = process.env.SKIN === LS_CONSTANTS.SKINS.COLOMBIA ? LOCALE.SPANISH : LOCALE.ENGLISH;

if (process.env.SKIN === LS_CONSTANTS.SKINS.COLOMBIA) {
  const bodyElement = document.querySelector('body');
  bodyElement.classList.add('blue-theme');
}

const translations = {
  [LOCALE.ENGLISH]: englishTranslations,
  [LOCALE.SPANISH]: spanishTranslations
};

initi18n({ dictionary: translations, language: locale, placeholder: '$' });

/**
 * Olaf function to create usable applications with passed routes, reducers, sagas and so on
 *
 * @function createOlafApplication
 * @returns {Application}
 */
createOlafApplication({
  name: 'app',
  thunk: true,
  hash: false,
  rootReactComponent: (
    <Root>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<LobyPage />} />
          <Route path={PAGES.LOGIN} element={<LoginPage />} />
          <Route path={PAGES.ODDS} element={<OddsPage />} />
          <Route path={PAGES.LIVE} element={<LivePage />} />
          <Route path={PAGES.BETSLIP} element={<BetslipPage />} />
          <Route path={PAGES.MY_BETS} element={<BetHistoryPage />} />
          <Route path={PAGES.RESULTS} element={<ResultsPage />} />
          <Route path={PAGES.BET_DETAIL} element={<BetHistoryPage />} />
          <Route path={PAGES.MY_DEPOSIT} element={<ExternaliFrame />} />
          <Route path={PAGES.MY_ACCOUNT} element={<ExternaliFrame />} />
          <Route path={PAGES.CASHIER} element={<ExternaliFrame />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Root>
  ),
  getRootDomElement: () => document.body.children[0].children[0],
  reducers: {
    app: appReducer,
    odds: oddsReducer,
    auth: authReducer,
    betslip: betslipReducer,
    betHistory: betHistoryReducer,
    results: resultsReducer,
  },
  sagas: {
    app: appSaga,
    auth: authSaga,
    betslip: betslipSaga,
    odds: oddsSaga,
    betHistory: betHistorySaga,
    results: resultsSaga,
  },
});
