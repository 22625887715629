/**
 * SVGComponent represent SVG icon.
 * Use this component for every SVG icon in application.
 *
 * @module SVG/SVGComponent
 * @property {object} context Context assigned form SVGProvider
 * @property {object} context.svg
 * @property {Array} context.svg.loadedSVGs
 * @property {Function} context.svg.checkSvg
 * @property {Function} context.svg.loadSvg
 * @property {Function} context.svg.getIdFromSrc
 * @property {Function} context.svg.getNameFromSrc
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
/**
 * @typedef {object} props
 * @property {string} src
 * @property {string} className
 * @property {Function} [onClick]
 */
export default class SVGComponent extends Component {
  componentDidMount() {
    if (!this.context.svg.checkSvg(this.props.src)) {
      this.context.svg.loadSvg(this.props.src);
    }
  }

  /**
   * If src change repeat process from componentWillMount
   *
   * @param {object} prevProps
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src && !this.context.svg.checkSvg(this.props.src)) {
      this.context.svg.loadSvg(this.props.src);
    }
  }

  /**
   * Render
   *
   * @returns {view}
   */
  render() {
    const { className, src, onClick, width, height } = this.props;
    return (
      <svg className={className} onClick={onClick} width={width} height={height}>
        <use xmlnslink="https://www.w3.org/1999/xlink" xlinkHref={this.context.svg.getIdFromSrc(src)} />
      </svg>
    );
  }
}

SVGComponent.contextTypes = {
  svg: PropTypes.object,
};
