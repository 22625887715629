import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslationContext } from 'reactjs-polyglot';
import { SVG_ICONS } from '../../constants/globals';
import { closeModal } from '../../store/reducer';
import { getModalData } from '../../store/selectors';
import SVGComponent from '../SVG/SVGComponent';
import { logError } from '../../utils/common';

const GeneralError = () => {
  const { t } = useTranslationContext();

  const dispatch = useDispatch();
  const data = useSelector(getModalData);

  useEffect(() => {
    logError({
      info: 'Error in modal',
      data,
      errorMessage: data.error
    });
  }, [data]);

  function close() {
    dispatch(closeModal());
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="text-center mb-3">
          <SVGComponent className="modal__content-icon" src={`${SVG_ICONS.utility}#icon__warning`} />
        </div>
        <div className="modal__content-title text-center">{t('modal.error.somethingWentWrong')}</div>
        <div className="modal__content-subTitle mt-3 text-center">{t('modal.error.tryAgain')}</div>
        <div className="modal__content-btnWrap text-center" onClick={close}>
          <div className="btn btn--primary has-arrow">
            <span>{t('common.word.close')}</span> <SVGComponent className="btn__arrow" src={`${SVG_ICONS.utility}#arrow-right`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralError;
