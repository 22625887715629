import { createSelector } from '@reduxjs/toolkit';
import { formatLastMatchStatistics, formatStandings } from '../../../utils/common';
import { getSelectedLeague, getSelectedLeagueId } from '../../../store/selectors';

export const getLoadingLeagueStatistics = (state) => state.odds?.leagueStatisticsLoadingStatus;
export const getOddsLoadingStatus = (state) => state.odds?.oddsLoadingStatus;

export const getOddLeagueStatistics = (state) => state.odds?.leagueStatistics;

export const getLeagueStatistics = createSelector(
  [getOddLeagueStatistics, getSelectedLeagueId, getSelectedLeague], 
  (leagueStatistics, leagueId, selectedLeague) => {
    if (!leagueStatistics[leagueId]) {
      return null;
    }

    const matches = leagueStatistics[leagueId].standings[0].value
      ? leagueStatistics[leagueId].standings[0].value.split('|')
      : [];
    const formattedMatches = formatStandings(matches);

    const roundStats = leagueStatistics[leagueId].statistics;
    const formattedStatistics = formatLastMatchStatistics(roundStats);

    return {
      standings: formattedMatches,
      statistics: formattedStatistics,
      leagueFlag: selectedLeague?.flag,
    };
  });