import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { checkSession } from './modules/auth/store/slice';
import { closeAllMenu, navigateTo, setCurrentRoute } from './store/reducer';
import { appStayAwake, fetchInitData } from './store/sagas';
import { getNavigateToPage } from './store/selectors';
import { PAGES } from './constants/globals';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://f001f1de82532524d0f832d0be8910ea@o4506257042767872.ingest.sentry.io/4506257244028928',
    integrations: [
      new Sentry.Replay(),
    ],
    environment: process.env.NODE_ENV,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  
}

const Root = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isInitialRender = useRef(true);

  const pageToGo = useSelector(getNavigateToPage);

  useEffect(() => {
    dispatch(fetchInitData());
    dispatch(checkSession());
    dispatch(appStayAwake());
  }, [dispatch]);

  useEffect(() => {
    // always redirect user to home page when loading app for first time
    if (isInitialRender.current) {
      navigate(PAGES.HOME);
      isInitialRender.current = false;
    }
  }, [navigate]);

  useEffect(() => {
    if (pageToGo !== null) {
      dispatch(navigateTo({ page: null }));
      navigate(pageToGo);
    }
  }, [dispatch, navigate, pageToGo]);

  useEffect(() => {
    // when a route changes, always close side menu
    dispatch(closeAllMenu());
    dispatch(setCurrentRoute({ route: location.pathname }));
  }, [dispatch, location]);

  return children;
};

export default Root;
