import { put, call, takeEvery, select } from 'redux-saga/effects';
import { API_URLS, MODALS } from '../../../constants/globals';
import { setActiveModal } from '../../../store/reducer';
import { requestGet } from '../../../utils/request';
import { formatBetInBetHistory } from '../helpers';
import { getSelectedBet } from './selectors';
import {
  loadBetHistory,
  loadBetHistoryFail,
  loadBetHistorySuccess,
  loadMoreBetHistory,
  reloadBetHistory,
  setSelectedBet,
} from './slice';

function* fetchBetHistory(action) {
  try {
    const numOfBets = 20;
    const queryParams = action?.payload
      ? `numbets=${numOfBets}&lastTimestamp=${action.payload.lastTimestamp}&lastRunning=${action.payload.lastRunning}`
      : `numbets=${numOfBets}`;
    const response = yield call(requestGet, `${API_URLS.MY_BETS}?${queryParams}`, true);

    if (response.status === -1) {
      throw new Error(response.error.message);
    }

    yield put(loadBetHistorySuccess({ data: response.data }));
  } catch (error) {
    yield put(loadBetHistoryFail(error.message));
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* reloadBetHistorySaga() {
  try {
    const numOfBets = 20;
    const response = yield call(requestGet, `${API_URLS.MY_BETS}?numbets=${numOfBets}`, true);

    if (response.status === -1) {
      throw new Error(response.error.message);
    }

    const selectedBet = yield select(getSelectedBet);

    if (selectedBet) {
      const newSelectedBet = response.data.bets.find((bet) => bet.id === selectedBet.id);
      if (newSelectedBet) {
        yield put(setSelectedBet({ bet: formatBetInBetHistory(newSelectedBet) }));
      }
    }

    yield put(loadBetHistorySuccess({ data: response.data }));
  } catch (error) {
    yield put(loadBetHistoryFail(error.message));
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

export default function* betHistorySaga() {
  yield takeEvery(loadBetHistory, fetchBetHistory);
  yield takeEvery(reloadBetHistory, reloadBetHistorySaga);
  yield takeEvery(loadMoreBetHistory, fetchBetHistory);
}
