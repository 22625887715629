import React, { useState } from 'react';
import { useTranslationContext } from 'reactjs-polyglot';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addStep } from 'user-step-tracker';
import { PAGES, SVG_ICONS } from '../constants/globals';
import ClassicLeagueList from '../modules/lobby/components/ClassicLeagueList';
import TurboLeagueList from '../modules/lobby/components/TurboLeagueList';
import { getIsCashoutEnabled, getIsClassicEnabled, getIsTurboEnabled, getUser } from '../modules/auth/store/selectors';
import { get5Markets, getDestinationUrls, getLeagues } from '../store/selectors';
import SVGComponent from './SVG/SVGComponent';
import { parseNum } from '../utils/parser';
import { setSelectedMarketId } from '../modules/odds/store/slice';
import { getMarketsFirstSubmarketId } from '../utils/odds';
import { convertToCentsDecimal } from '../utils/common';
import CashoutLeagueList from '../modules/lobby/components/CashoutLeagueList';

const SideMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslationContext();

  const leagues = useSelector(getLeagues);
  const shownMarkets = useSelector(get5Markets);
  const destinationUrls = useSelector(getDestinationUrls);
  const user = useSelector(getUser);
  const isClassicEnabled = useSelector(getIsClassicEnabled);
  const isTurboEnabled = useSelector(getIsTurboEnabled);
  const isCashoutEnabled = useSelector(getIsCashoutEnabled);

  const [leagueAccordionOpen, setLeagueAccordionOpen] = useState();

  function selectLeague() {
    const [market] = shownMarkets;

    if (market) {
      dispatch(
        setSelectedMarketId({
          selectedMarketId: market.id,
          selectedSubmarketId: getMarketsFirstSubmarketId(market),
        })
      );
    }
    navigate(PAGES.ODDS);
  }

  function toggleLeagueAccordion() {
    setLeagueAccordionOpen((prev) => !prev);
  }

  function navigateToDepositPage() {
    addStep('USER_NAVIGATE_DEPOSIT_SideMenu');
    navigate(PAGES.MY_DEPOSIT);
  }

  return (
    <>
      <nav className="pml">
        <div className="pml__wrap">
          <div className="pml__logo mt-3" />
          <div className="pml__scrollable flex-grow-1">
            {user ? (
              <div className="deposit">
                <div className="deposit__head">
                  <div>{t('sideMenu.label.accountId')}</div>
                  <div>{user?.id}</div>
                </div>
                <div className="deposit__row">
                  <div>{t('common.word.balance')}</div>
                  <div className="txt-cut pl-5">
                    {user?.balance && parseNum(convertToCentsDecimal(user?.balance.amount))}{' '}
                    {user?.balance && user?.balance.currency}
                  </div>
                </div>
                <div className="deposit__btn mt-3" onClick={navigateToDepositPage}>
                  {t('sideMenu.button.makeDeposit')}
                </div>
              </div>
            ) : null}
            <div className="accordion mt-3">
              <div className="accordion__item">
                <div
                  className={`accordion__itemToggle ${leagueAccordionOpen ? 'is-open' : ''}`}
                  onClick={toggleLeagueAccordion}
                >
                  <div className="accordion__itemWrap">
                    <SVGComponent className="md-icon accordion__itemIcon" src={`${SVG_ICONS.navbar}#league-select`} />
                    <span className="ml-3">{t('sideMenu.button.leagueSelect')}</span>
                  </div>
                  <SVGComponent
                    className="sm-icon accordion__itemDropdown"
                    src={`${SVG_ICONS.utility}#icon__arrow-down`}
                  />
                </div>
                <div className="accordion__itemContent">
                  <ClassicLeagueList leagues={leagues} selectLeague={selectLeague} isEnabled={isClassicEnabled} />
                  <CashoutLeagueList leagues={leagues} selectLeague={selectLeague} isEnabled={isCashoutEnabled} />
                  <TurboLeagueList leagues={leagues} selectLeague={selectLeague} isEnabled={isTurboEnabled} />
                </div>
              </div>
              <div className="accordion__item">
                <a href={destinationUrls?.home} className="accordion__itemToggle">
                  <div className="accordion__itemWrap">
                    <SVGComponent className="md-icon" src={`${SVG_ICONS.navbar}#home-nav`} />
                    <span className="ml-3">{t('sideMenu.button.mobileHome')}</span>
                  </div>
                </a>
              </div>
              <div className="accordion__item">
                <a href={destinationUrls?.tc} className="accordion__itemToggle">
                  <div className="accordion__itemWrap">
                    <SVGComponent className="md-icon" src={`${SVG_ICONS.navbar}#tc`} />
                    <span className="ml-3">{t('sideMenu.button.terms')}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="pml-mask" />
    </>
  );
};

export default SideMenu;
