import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslationContext } from 'reactjs-polyglot';
import { useNavigate } from 'react-router-dom';
import { addStep } from 'user-step-tracker';
import { GAME_MODE, PAGES, SVG_ICONS } from '../../constants/globals';
import { setUserData } from '../../modules/auth/store/slice';
import { clearPlacedBets, closeModal, setGameMode, setRoundData } from '../../store/reducer';
import { getDestinationUrls } from '../../store/selectors';
import SVGComponent from '../SVG/SVGComponent';
import { clearCashedOutBets } from '../../modules/bet-history/store/slice';

const SessionExpired = () => {
  const { t } = useTranslationContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const destinationUrls = useSelector(getDestinationUrls);

  useEffect(() => {
    dispatch(setUserData({ user: null }));
    dispatch(clearPlacedBets());
    dispatch(clearCashedOutBets());
    navigate(PAGES.HOME);
  }, [dispatch, navigate]);

  function goToLogin() {
    addStep('USER_GO_TO_LOGIN_SessionExpired');
    dispatch(setGameMode({ gameMode: GAME_MODE.CASH_OUT }));
    dispatch(setRoundData({ roundData: null, roundResults: null }));

    navigate(PAGES.LOGIN);
    dispatch(closeModal());
  }

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__content-close" onClick={goToLogin}>
          <span className="modal__content-closeBtn">
            <SVGComponent className="md-icon" src={`${SVG_ICONS.utility}#icon__close`} />
          </span>
        </div>
        <div className="modal__content-title text-center">{t('modal.session.title')}</div>
        <div className="modal__content-btnWrap text-center">
          <div className="btn btn--tertiary has-arrow" onClick={goToLogin}>
            <span>{t('common.word.login')}</span> <SVGComponent className="btn__arrow" src={`${SVG_ICONS.utility}#arrow-right`} />
          </div>
          <a href={destinationUrls?.home} className="btn btn--primary has-arrow">
            <span>{t('modal.session.backToBet9ja')}</span>{' '}
            <SVGComponent className="btn__arrow btn__arrow--left" src={`${SVG_ICONS.utility}#arrow-back`} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SessionExpired;
