/* eslint-disable jsdoc/valid-types */
import { SplitManager, SystemManager, SplitMaxWinCalculator } from 'system-manager';
/**
 * @namespace utils/systemManager
 */
/**
 * Return matches per leagueId-matchId and number of matches for system bet
 *
 * @memberof utils/systemManager
 * @param {Array} odds
 * @returns {Array}
 */
export function SystemMatches(odds) {
  const matches = {};
  let numOfMatchesForSys = 0;
  odds.forEach((odd) => {
    const i = `${odd.leagueId}-${odd.matchId}`;
    if (!matches[i]) {
      matches[i] = [];
      numOfMatchesForSys += 1;
    }
    matches[i].push({
      odd: odd.odd,
      winScores: odd.winningScores || odd?.selection?.winningScores || '',
    });
  });

  return { numOfMatchesForSys, oddsPerMatch: Object.values(matches) };
}

/**
 * @external 'node_modules/system-manager'
 * @see {@link https://gitlab.com/providus.mu/fe-utils/system-bet-calculator}
 */
/**
 * Calling SystemManager or SplitManager library and return response
 *
 * @memberof utils/systemManager
 * @param {Array} odds
 * @param {number} bonusPercentages
 * @param {Array} bonusOddsThreshold
 * @param {object} isSplit
 * @param {number} winCap
 * @returns {object}
 */
export function SystemSplitManager(odds, bonusPercentages, bonusOddsThreshold, isSplit, winCap) {
  if (odds.length > 0) {
    if (isSplit) {
      const result = SplitManager(odds, bonusPercentages, bonusOddsThreshold);
      const result2 = SplitMaxWinCalculator(odds, bonusPercentages.slice(0), bonusOddsThreshold, 1, winCap);
      result.ODDMAX = result2.win - result2.bonus;
      result.BONUSMAX = result2.bonus;
      result.POTWINMAX = result2.win;
      return result;
    }

    return SystemManager(odds, bonusPercentages, bonusOddsThreshold);
  }
  return {};
}

/**
 * Return matches per leagueId-matchId, stake and number of matches for multiple singles
 *
 * @memberof utils/systemManager
 * @param {Array} odds
 * @param {Array} stakes
 * @returns {object}
 */
export function singlesMatches(odds, stakes) {
  const matches = {};
  odds.forEach((odd, index) => {
    const key = `${odd.leagueId}-${odd.matchId}`;
    if (!matches[key]) {
      matches[key] = [];
    }
    matches[key].push({
      odd: odd.odd,
      winScores: odd.winningScores,
      stake: stakes[index],
    });
  });

  return Object.keys(matches).map((el) => matches[el]);
}

/**
 * Odds grouped by match
 *
 * @memberof utils/systemManager
 * @param {Array} odds
 * @param {Array} stakes
 * @returns {Array}
 */
export function oddsByMatches(odds, stakes) {
  const matchesOdds = singlesMatches(odds, stakes);
  const matches = Object.keys(matchesOdds);
  return matches.map((m) => matchesOdds[m]);
}
