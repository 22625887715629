import React from 'react';
import { useTranslationContext } from 'reactjs-polyglot';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { GAME_MODE, PAGES } from '../../../constants/globals';
import { loadRound, setGameMode, setLeagueId } from '../../../store/reducer';
import { getGameMode } from '../../../store/selectors';
import { clearOddsLeagueStatistics, removeAllOddSelections } from '../../odds/store/slice';
import { clearResultsStats } from '../../results/store/slice';

const TurboLeagueList = ({ leagues = [], selectLeague, isEnabled }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslationContext();

  const gameMode = useSelector(getGameMode);

  function onSelectLeague(id) {
    if (!isEnabled) {
      navigate(PAGES.LOGIN);
      return;
    }
    dispatch(setLeagueId(id));
    selectLeague();

    dispatch(setGameMode({ gameMode: GAME_MODE.TURBO }));

    if (gameMode !== GAME_MODE.TURBO && location.pathname !== PAGES.HOME) {
      dispatch(removeAllOddSelections());
      dispatch(clearOddsLeagueStatistics());
      dispatch(loadRound());
      dispatch(clearResultsStats());
    }
  }

  function getButtonId(leagueName) {
    return `lobbyLeagueBtn${leagueName.replace(' ', '')}Turbo`;
  }

  return (
    <div className="leagues">
      <div className="leagues__selections-wrap">
        {leagues.map((l) => (
          <div
            id={getButtonId(l.name)}
            className="leagues__selections-item"
            key={l.id}
            onClick={onSelectLeague.bind(null, l.id)}
          >
            <div className={`leagues__selections-icon icon-${l.flag}-turbo`} />
            <div className="leagues__selections-itemTitle">{l.name}</div>
          </div>
        ))}
      </div>
      <div className="leagues__selections-title text-center text-uppercase">{t('common.word.stadium')} {t('common.word.turbo')}</div>
    </div>
  );
};

export default TurboLeagueList;
