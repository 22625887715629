import { BET_TYPE, BET_TYPE_VALUES, DIGITS } from '../../../constants/globals';
import { convertToCentsInt, getBetType } from '../../../utils/common';

export function getBetDataForSingles(selections, stakes) {
  const stakesMapObj = stakes.reduce((acc, curr, index) => {
    acc[index] = curr;
    return acc;
  }, {});

  const selectionsString = selections
    .filter((s, index) => stakesMapObj[index])
    .map((s) => `${s.leagueId}:${s.matchId + 1}:${s.selectionId}`)
    .join('$');

  const stakesString = stakes
    .filter((s) => s)
    .map((s, i) => `${i + 1}:${convertToCentsInt(s)}`)
    .join('&');

  const totalStakes = convertToCentsInt(stakes.reduce((acc, curr) => acc + curr, 0));
  const totalString = `&T:${totalStakes}`;

  return `${BET_TYPE_VALUES[BET_TYPE.SINGLE]}|${selectionsString}|${stakesString}${totalString}`;
}

export function getBetDataForMultiples(selections, stakes) {
  const selectionsString = selections.map((s) => `${s.leagueId}:${s.matchId + 1}:${s.selectionId}`).join('$');
  const totalStake = convertToCentsInt(stakes[0]);

  return `${BET_TYPE_VALUES[BET_TYPE.MULTIPLE]}|${selectionsString}|${totalStake}`;
}

export function getBetDataForCombi(selections, stakes, totalStakes, combinationsData) {
  const selectionsString = selections.map((s) => `${s.leagueId}:${s.matchId + 1}:${s.selectionId}`).join('$');
  const isSplitBet = getBetType(selections) === BET_TYPE.SPLIT_BET;

  let stakesString = stakes
    .map((s, i) => (s ? `${i + 1}:${convertToCentsInt(s)}` : ''))
    .filter((s) => s)
    .join('&');

  if (isSplitBet) {
    const indexSplitbetType = DIGITS.findIndex((x) => x === combinationsData[0].type) + 1;
    stakesString = `${indexSplitbetType}:${convertToCentsInt(stakes[0])}`;
  }

  const totalStakesInt = convertToCentsInt(totalStakes);
  const totalString = `&T:${totalStakesInt}`;
  const betTypeIndicator = isSplitBet ? BET_TYPE_VALUES[BET_TYPE.SPLIT_BET] : BET_TYPE_VALUES[BET_TYPE.COMBINATION];

  return `${betTypeIndicator}|${selectionsString}|${stakesString}${totalString}`;
}

export function parseRoundBetToCouponData(
  betData,
  betSelections,
  winningScoresMap,
  potentialWin,
  couponId,
  roundId,
  couponOdds,
  isFreeBet
) {
  let group;

  let [betType, selections, stake] = betData.split('|');
  betType = parseInt(betType);
  switch (betType) {
    case BET_TYPE_VALUES[BET_TYPE.SINGLE]:
      group = 1;
      stake = stake.split(':').pop();
      break;
    case BET_TYPE_VALUES[BET_TYPE.MULTIPLE]:
      group = selections.split('$').length;
      break;
    case BET_TYPE_VALUES[BET_TYPE.COMBINATION]:
      group = Math.min(
        ...stake
          .split('&')
          .slice(0, -1)
          .map((x) => parseInt(x.split(':')[0]))
      );
      stake = stake.split(':').pop();
      break;
    case BET_TYPE_VALUES[BET_TYPE.SPLIT_BET]:
      group = parseInt(stake.split(':')[0]);
      stake = stake.split(':').pop();
      break;
    default:
      break;
  }

  const parsedSelections = betSelections.map((s) => {
    const ws = s.winningScores.replace(/:/g, '-');
    const { alwaysWin, alwaysLose, name } = winningScoresMap[s.selectionId];
    return {
      leagueId: s.leagueId,
      matchId: s.matchId,
      selectionId: s.selectionId,
      selectionIndex: s.selectionIndex,
      odd: s.odd,
      winningScores: ws,
      alwaysWin,
      alwaysLose,
      name,
      fullName: s.fullName,
    };
  });

  return {
    group,
    bonus: isFreeBet,
    selections: parsedSelections,
    stake,
    betType,
    potentialWin,
    couponId,
    roundId,
    couponOdds,
  };
}
