import { put, call, takeEvery, takeLeading, delay, select } from 'redux-saga/effects';
import { API_URLS, GAME_MODE, MODALS } from '../../../constants/globals';
import {
  clearPlacedBets,
  getOpenBets,
  loadRoundBets,
  setActiveModal,
  setExternalIFrameUrl,
  setGameMode,
  loadRound,
} from '../../../store/reducer';
import { get5Markets } from '../../../store/selectors';
import { sortFreeBets } from '../../../utils/common';
import { getMarketsFirstSubmarketId } from '../../../utils/odds';
import { requestGet, requestPost } from '../../../utils/request';
import { setSelectedBet } from '../../bet-history/store/slice';
import { clearOddsLeagueStatistics, removeAllOddSelections, setSelectedMarketId } from '../../odds/store/slice';
import {
  loginFail,
  login as loginAction,
  logout as logoutAction,
  loginSuccess,
  checkSessionFail,
  checkSessionSuccess,
  checkSession as checkSessionAction,
  getUserBalanceSuccess,
  getUserBalance,
  loadUserLoyaltySuccess,
  loadUserLoyalty,
  getUserBalanceFail,
} from './slice';
import { clearResultsStats } from '../../results/store/slice';

function* login(action) {
  try {
    const response = yield call(requestPost, API_URLS.LOGIN, action.payload.credentials);
    if (response.status === -1) {
      yield put(loginFail(response.error.message));
      return;
    }

    yield put(loadUserLoyalty());
    yield put(getOpenBets());
    yield put(loadRoundBets());

    if (response.data.freeBets.length > 0) {
      yield put(
        setActiveModal({
          modal: MODALS.FREE_BETS,
        })
      );
    }

    sessionStorage.setItem('lastLoggedInClient', response.data.user.id);

    yield put(
      loginSuccess({
        user: {
          data: response.data.user,
          sessionId: response.data.sessionId,
          freeBets: sortFreeBets(response.data.freeBets),
        },
        targetPageAfterLogin: action.payload.targetPageAfterLogin,
      })
    );

    const hasCashout = response.data.user.gamesEnabled.some((g) => g === GAME_MODE.CASH_OUT);
    const [gameMode] = response.data.user.gamesEnabled;

    yield put(setGameMode({ gameMode: hasCashout ? GAME_MODE.CASH_OUT : gameMode }));

    const shownMarkets = yield select(get5Markets);

    if (!shownMarkets || shownMarkets.length === 0) {
      return;
    }
    const [market] = shownMarkets;

    yield put(
      setSelectedMarketId({
        selectedMarketId: market.id,
        selectedSubmarketId: getMarketsFirstSubmarketId(market),
      })
    );
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* logout() {
  try {
    yield put(setGameMode({ gameMode: GAME_MODE.CASH_OUT }));
    yield put(loadRound());
    yield put(removeAllOddSelections());
    yield put(clearOddsLeagueStatistics());
    yield put(setExternalIFrameUrl({ selectedExternalIframeUrl: null }));
    yield put(setSelectedBet({ bet: null }));
    yield put(clearPlacedBets());
    yield put(clearResultsStats());
    yield call(requestPost, API_URLS.LOGOUT, {});
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* checkSession() {
  try {
    const response = yield call(requestPost, API_URLS.CHECK_SESSION, null, true);

    if (response.status === -1) {
      yield put(checkSessionFail());
      return;
    }

    sessionStorage.setItem('lastLoggedInClient', response.data.user.id);

    yield put(
      checkSessionSuccess({
        user: {
          data: response.data.user,
          sessionId: response.data.sessionId,
          freeBets: sortFreeBets(response.data.freeBets),
        },
      })
    );

    if (response.data.hasNewFreeBets) {
      yield put(
        setActiveModal({
          modal: MODALS.FREE_BETS,
        })
      );
    }

    yield put(loadUserLoyalty());
    yield put(getOpenBets());
    yield put(loadRoundBets());
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* getUserBalanceSaga() {
  try {
    const response = yield call(requestGet, API_URLS.BALANCE);

    if (response.status === -1) {
      yield put(getUserBalanceFail());
      return;
    }
    yield put(getUserBalanceSuccess({ balance: response.data }));
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* fetchUserBonus(action) {
  const delayMilliseconds = 3000;
  if (action.payload && action.payload.isAfterPlacebet === true) {
    // delay is needed to give the backend time to update the latest value for the user loyalty
    yield delay(delayMilliseconds);
  }

  try {
    const response = yield call(requestGet, API_URLS.USER_BONUS);

    if (response.status === 1) {
      yield put(
        loadUserLoyaltySuccess({
          userBonus: response.data,
        })
      );
    }
  } catch (error) {
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

export default function* authSaga() {
  yield takeLeading(loginAction, login);
  yield takeEvery(logoutAction, logout);
  yield takeEvery(checkSessionAction, checkSession);
  yield takeEvery(getUserBalance, getUserBalanceSaga);
  yield takeEvery(loadUserLoyalty, fetchUserBonus);
}
