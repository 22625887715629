import { put, call, takeEvery, select } from 'redux-saga/effects';
import { API_URLS, GAME_MODE, MODALS } from '../../../constants/globals';
import { setActiveModal } from '../../../store/reducer';
import { getGameMode } from '../../../store/selectors';
import { requestGet } from '../../../utils/request';
import {
  loadLeagueResults,
  loadLeagueResultsFail,
  loadLeagueResultsSuccess,
  loadLeagueTableData,
  loadLeagueTableDataFail,
  loadLeagueTableDataSuccess,
} from './slice';

function* fetchLeagueResults(action) {
  try {
    const gameMode = yield select(getGameMode);
    let url = gameMode === GAME_MODE.TURBO ? API_URLS.RESULTS_TURBO : API_URLS.RESULTS.replace('$gameId', gameMode);
    url = url.replace('$leagueId', action.payload.leagueId);
    const response = yield call(requestGet, `${url}`, true);

    if (response.status === -1) {
      throw new Error(response.error.message);
    }

    yield put(loadLeagueResultsSuccess({ data: response.data }));
  } catch (error) {
    yield put(loadLeagueResultsFail(error.message));
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

function* fetchLeagueTableData(action) {
  try {
    const gameMode = yield select(getGameMode);
    let url =
      gameMode === GAME_MODE.TURBO
        ? API_URLS.LEAGUE_STATISTICS_TURBO
        : API_URLS.LEAGUE_STATISTICS.replace('$gameId', gameMode);

    url = url.replace('$leagueId', action.payload.leagueId);

    const response = yield call(requestGet, url, true);

    if (response.status === -1) {
      throw new Error(response.error.message);
    }

    yield put(loadLeagueTableDataSuccess({ leagueId: action.payload.leagueId, data: response.data }));
  } catch (error) {
    yield put(loadLeagueTableDataFail(error.message));
    yield put(
      setActiveModal({
        modal: error.modal || MODALS.GENERAL_ERROR,
        data: {
          error: error.message,
        },
      })
    );
  }
}

export default function* resultsSaga() {
  yield takeEvery(loadLeagueResults, fetchLeagueResults);
  yield takeEvery(loadLeagueTableData, fetchLeagueTableData);
}
