/* eslint-disable jsdoc/valid-types */
/**
 * @namespace utils/request
 */

import { ERRORS, MODALS } from '../constants/globals';
import VERSIONS from '../../versions.json';

/**
 * Parses the JSON returned by a network request
 *
 * @memberof utils/request
 * @param  {object} response A response from a network request
 * @returns {response}          The parsed JSON from the request
 */
function parseJSON(response) {
  return response.json();
}

/**
 * Open session expired modal
 *
 * @memberof utils/request
 * @param {object} response
 * @param {boolean} session
 * @returns {response}
 */
function requestCheckSession(response, session) {
  const errorsRelatedToSession = [ERRORS.SESSION_EXPIRED, ERRORS.AUTH_REQUIRED, ERRORS.ERROR_DURING_AUTH];
  const hasErrorsRelatedToSession = errorsRelatedToSession.some((error) => response.error.code === error);
  if (session && response.status === -1 && hasErrorsRelatedToSession) {
    const error = new Error(MODALS.SESSION_EXPIRED);
    error.code = response.error.code;
    error.modal = MODALS.SESSION_EXPIRED;
    throw error;
  }
  return response;
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @memberof utils/request
 * @param  {object} response   A response from a network request
 * @returns {response|Error} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @memberof utils/request
 * @param  {string} url       The URL we want to request
 * @param  {object} data      The data we want to pass to API
 * @param  {boolean} session  Indicates whether to check session when calling API
 * @returns {response}         The response data
 */
export function requestPost(url, data, session) {
  const options = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include', // include, *same-origin, omit

    headers: {
      'Content-Type': 'application/json',
      'X-Stadium-App-Version': VERSIONS.JS,
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  };
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then((res) => requestCheckSession(res, session));
}
/**
 * Requests a URL, returning a promise
 *
 * @memberof utils/request
 * @param  {string} url       The URL we want to request
 * @param  {boolean} session  Indicates whether to check session when calling API
 * @returns {response}           The response data
 */
export function requestGet(url, session) {
  const options = {
    method: 'GET',
    credentials: 'include',
  };
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then((res) => requestCheckSession(res, session));
}

/**
 * @memberof utils/request
 * @typedef {object} response
 * @property {number} status - Represent boolean, -1 is error, 1 is success
 * @property {error} error - Only exist when status is -1
 * @property {object} data - Only exist when status is 1
 */

/**
 * @memberof utils/request
 * @typedef {object} error
 * @property {number} code
 * @property {string} message
 */
