import React from 'react';
import { useTranslationContext } from 'reactjs-polyglot';
import { SVG_ICONS } from '../constants/globals';
import { convertToCentsDecimal } from '../utils/common';
import { parseNum } from '../utils/parser';
import SVGComponent from './SVG/SVGComponent';

const BonusInformation = ({ isOpen, bonusLevels = [], currency }) => {
  const { t } = useTranslationContext();

  return (
    <div className={`bonus__dropdownContent ${isOpen ? 'is-open' : ''}`}>
      <div className="bonus__infoWrap">
        <div className="bonus__info">
          <div className="bonus__infoLabel text-uppercase">
            <span>{t('loyaltyMenu.label.bonusInformationTitle')}</span>
          </div>
          <div className="bonus__info-inner">
            <div className="bonus__infoTxt">
              {t('loyaltyMenu.label.bonusInformation')}
            </div>
          </div>
        </div>
      </div>
      <div className="bonus__table">
        {bonusLevels.map((b) => (
          <div key={b.name} className="bonus__tableRow">
            <div className="bonus__tableCell">
              <SVGComponent className="md-icon" src={`img/${SVG_ICONS.utility}#${b.name.toLowerCase()}`} />
              <div className="bonus__tableLabel ml-2">{t(`bonusLeveling.${b.id}.name`)}</div>
            </div>
            <div className="bonus__tableCell">
              <div className="bonus__tableAmount">
                {parseNum(convertToCentsDecimal(b.amount))} {currency}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BonusInformation;
