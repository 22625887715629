/**
 * @constant
 */
export const PAGES = {
  HOME: '/',
  LOGIN: '/login',
  ODDS: '/odds',
  LIVE: '/live',
  TURBO_LIVE: '/live-turbo',
  BETSLIP: '/betslip',
  MY_DEPOSIT: '/my-deposit',
  MY_ACCOUNT: '/my-account',
  MY_BETS: '/my-bets',
  RESULTS: '/results',
  CASHIER: '/cashier',
  BET_DETAIL: '/bet-detail',
};

export const PUSH_SERVER_URL = process.env.PUSH_SERVER_URL || 'missing-push-server-url';

// TODO add API_URL into build-config.json
/**
 * @constant
 */
const API_PATH = process.env.NODE_ENV === 'development' ? '/api' : process.env.API_URL;

/**
 * @constant
 */
export const API_URLS = {
  INIT: `${API_PATH}/game/init`,
  ROUND: `${API_PATH}/game/$gameId/round`,
  TURBO: `${API_PATH}/turbo/round`,
  LOGIN: `${API_PATH}/session/login`,
  CHECK_SESSION: `${API_PATH}/session/checksession`,
  PLACEBET: `${API_PATH}/bet/placebet`,
  LOGOUT: `${API_PATH}/session/logout`,
  BALANCE: `${API_PATH}/user/balance`,
  LEAGUE_STATISTICS: `${API_PATH}/game/$gameId/leagueStatistics/$leagueId`,
  LEAGUE_STATISTICS_TURBO: `${API_PATH}/turbo/leagueStatistics/$leagueId`,
  ACCOUNT_DEPOSIT: `${API_PATH}/session/geturl?destination=depositMobile`,
  MY_ACCOUNT: `${API_PATH}/session/geturl?destination=bolt_mobile_menu_account`,
  CASHIER: `${API_PATH}/session/geturl?destination=bolt_mobile_menu_cashier`,
  MY_BETS: `${API_PATH}/bet/list`,
  OPEN_BETS: `${API_PATH}/bet/numberopenbets`,
  ROUND_BETS: `${API_PATH}/bet/roundbets`,
  CASH_OUT: `${API_PATH}/bet/cashout`,
  RESULTS: `${API_PATH}/game/$gameId/league/$leagueId/results`,
  RESULTS_TURBO: `${API_PATH}/turbo/league/$leagueId/results`,
  USER_BONUS: `${API_PATH}/loyalty/userbonus`,
};

export const GAME_MODE = {
  CLASSIC: 1,
  TURBO: 2,
  CASH_OUT: 5,
};

export const COUNTRY_TIMEZONE = {
  nigeria: 'Africa/Lagos',
};

export const COUNTRY_OFFSET = {
  NIGERIA: {
    OFFSET: 60,
  },
};

export const SVG_ICONS = {
  navbar: 'nav-icons.svg',
  flags: 'flags.svg',
  shirts: 'shirts--',
  utility: 'utility-icons.svg',
  warning: 'warning.svg',
  bottomMenu: 'bottom-menu-icons.svg',
  bottomMenuBlue: 'bottom-menu-icons-blue.svg',
};

export const LEAGUE_SHIRT = {
  de: 'bundesliga',
  nl: 'eredivisie',
  es: 'liga',
  fr: 'ligue1',
  uk: 'premier',
  pt: 'primeira',
  it: 'seriea',
};

export const ERRORS = {
  SESSION_EXPIRED: 1111,
  RESULTS_NOT_READY: 10201,
  NEW_ROUND_NOT_READY: 10202,
  CHECK_SESSION_CLOSED: 10008,
  CHECK_SESSION_EXPIRED: 10009,
  CHECK_SESSION_INVALID: 10010,
  AUTH_REQUIRED: 10006,
  ERROR_DURING_AUTH: 10007,
  PLACEBET_COUPON_VALIDATION_FREE_BET_ERROR: 10117,
};

export const CASHOUT_ERRORS = {
  CASHOUT_VALIDATION_COUPON_SETTLED_ERROR: 10404,
  CASHOUT_VALIDATION_COUPON_ALREADY_CASHOUT_ERROR: 10405,
  CASHOUT_VALIDATION_SLOT_ERROR: 10407,
  CASHOUT_SETTLEMENT_ERROR: 10410,
  CASHOUT_SETTLEMENT_FAILED: 10411,
};

export const MARKET_IDS = {
  MARKET_1X2: 1,
  MARKET_DOUBLE_CHANCE: 2,
  MARKET_GOAL_NO_GOAL: 3,
  MARKET_CORRECT_SCORE: 4,
  MARKET_OVER_UNDER: 5,
  MARKET_HOME_OVER_UNDER: 6,
  MARKET_AWAY_OVER_UNDER: 7,
  MARKET_1X2_OVER_UNDER: 8,
  MARKET_MULTIGOAL: 9,
  MARKET_DC_OVER_UNDER: 10,
  MARKET_MULTIGOAL_HOME: 11,
  MARKET_MULTIGOAL_AWAY: 12,
};

export const SELECTION_LABEL = {
  101: 'Home win',
  102: 'Draw',
  103: 'Away Win',
};

export const BET_TYPE = {
  NO_BET: 'NO_BET',
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
  COMBINATION: 'COMBINATION',
  SPLIT_BET: 'SPLIT_BET',
  FREEBET: 'FREEBET',
  OTHER: 'OTHER',
};

export const BET_TYPE_LABEL = {
  [BET_TYPE.NO_BET]: 'No Bet',
  [BET_TYPE.SINGLE]: 'Single',
  [BET_TYPE.MULTIPLE]: 'Multiple',
  [BET_TYPE.COMBINATION]: 'Combi',
  [BET_TYPE.SPLIT_BET]: 'Split',
  [BET_TYPE.FREEBET]: 'Free Bets',
};

export const BET_TYPE_VALUES = {
  [BET_TYPE.SINGLE]: 1,
  [BET_TYPE.MULTIPLE]: 2,
  [BET_TYPE.COMBINATION]: 3,
  [BET_TYPE.SPLIT_BET]: 4,
};

export const BET_TYPE_NUMERIC_KEY = {
  1: BET_TYPE.SINGLE,
  2: BET_TYPE.MULTIPLE,
  3: BET_TYPE.COMBINATION,
  4: BET_TYPE.SPLIT_BET,
};

export const EPSILON = 0.000001;

export const LOADER_STATUS = {
  START: 0,
  IN_PROGRESS: 1,
  SUCCESS: 2,
  ERROR: 3,
};

export const DIGITS = [
  'Singles',
  'Doubles',
  'Trebles',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
  'Ten',
  'Eleven',
  'Twelve',
  'Thirteen',
  'Fourteen',
  'Fifteen',
  'Sixteen',
  'Seventeen',
  'Eighteen',
  'Nineteen',
  'Twenty',
];

export const MARKETS_WITH_SUBMARKETS = {
  5: '',
  6: '',
  7: '',
  8: '',
  10: '',
};

export const MATCH_RESULT_LABEL = {
  win: 'W',
  lose: 'L',
  draw: 'D',
  void: 'V',
};

export const GET_USER_BALANCE_SECONDS_ALLOWANCE = 10;

export const MODALS = {
  LIVE_FINISH: 'Live Finish',
  SESSION_EXPIRED: 'Session Expired',
  NO_BETTING_TIME: 'No Betting Time',
  VIEW_PLACED_BETS: 'Perview placed bets',
  LIVE_STARTED: 'Live is going to start!',
  MAX_SELECTIONS: 'Max selections',
  GENERAL_ERROR: 'Something went wrong!',
  FREE_BETS: 'Free bets',
};

export const MENUS = {
  SIDE: 'Side',
  LEAGUE: 'League',
  ACCOUNT: 'Account',
  BONUS: 'BONUS',
};

export const PAUSE_ON_HALFTIME = 2000;

export const CLOSE_MODAL_SECONDS = 7;

export const PAGES_NOT_TO_REDIRECT_TO_LIVE = [
  PAGES.CASHIER,
  PAGES.MY_ACCOUNT,
  PAGES.MY_DEPOSIT,
  PAGES.LOGIN,
  PAGES.HOME,
];

export const CASHED_OUT_STATUS = {
  NOT_INVOKED: 0,
  LOADING: 1,
  CASHED_OUT: 2,
};

export const { STATIC_VERSION } = process.env;

export const MIN_CASHOUT_AMOUNT = 100; // value is in cents

export const LOCALE = {
  ENGLISH: 'en',
  SPANISH: 'es'
};

// number of seconds to add to live start and end of turbo live date to compensate for time when loading api
export const TURBO_LIVE_OFFSET_SECONDS = 1; 