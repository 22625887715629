import { createSlice } from '@reduxjs/toolkit';
import { LOADER_STATUS } from '../../../constants/globals';

const initialState = {
  bets: {},
  loadingStatus: LOADER_STATUS.IN_PROGRESS,
  lastTimestamp: null,
  lastRunning: null,
  hasMorePages: false,
  selectedBet: null,
  cashedOutBets: {},
};

const betHistorySlice = createSlice({
  name: 'betHistory',
  initialState,
  reducers: {
    loadBetHistory(state) {
      state.bets = {};
      state.loadingStatus = LOADER_STATUS.IN_PROGRESS;
    },
    loadMoreBetHistory(state) {
      state.loadingStatus = LOADER_STATUS.IN_PROGRESS;
    },
    loadBetHistorySuccess(state, action) {
      const betsByDate = action.payload.data.bets.reduce((acc, curr) => {
        const date = new Date(curr.placed);
        const key = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
        acc[key] = acc[key] ? acc[key].concat(curr) : [curr];
        return acc;
      }, state.bets);

      state.bets = betsByDate;
      state.lastTimestamp = action.payload.data.lastTimestamp;
      state.lastRunning = action.payload.data.lastRunning;
      state.hasMorePages = action.payload.data.bets.length > 0 && action.payload.data.lastTimestamp !== '';
      state.loadingStatus = LOADER_STATUS.START;
    },
    loadBetHistoryFail(state) {
      state.loadingStatus = LOADER_STATUS.START;
    },
    setSelectedBet(state, action) {
      state.selectedBet = action.payload.bet;
    },
    reloadBetHistory(state) {
      state.bets = {};
      state.loadingStatus = LOADER_STATUS.IN_PROGRESS;
    },
    tryCashoutSaga(state, action) {
      const { roundId, couponId } = action.payload;
      if (!state.cashedOutBets[roundId]) {
        state.cashedOutBets[roundId] = {};
      }
      state.cashedOutBets[roundId][couponId] = 0;
    },
    betCashoutSuccess(state, action) {
      const { roundId, cashoutValue, couponId } = action.payload;
      if (!state.cashedOutBets[roundId]) {
        state.cashedOutBets[roundId] = {};
      }
      state.cashedOutBets[roundId][couponId] = cashoutValue;
      Object.keys(state.cashedOutBets).forEach((key) => {
        if (parseInt(key) !== roundId) {
          delete state.cashedOutBets[key];
        }
      });
    },
    bulkBetCashoutSuccess(state, action) {
      const { roundId, coupons } = action.payload;

      if (!state.cashedOutBets[roundId]) {
        state.cashedOutBets[roundId] = {};
      }

      for (let i = 0; i < coupons.length; i += 1) {
        if (coupons[i].win) {
          state.cashedOutBets[roundId][coupons[i].betId] = coupons[i].win;
        }
      }
      Object.keys(state.cashedOutBets).forEach((key) => {
        if (parseInt(key) !== roundId) delete state.cashedOutBets[key];
      });
    },
    betCashoutFail(state, action) {
      const { roundId, couponId } = action.payload;
      if (state.cashedOutBets[roundId]) {
        delete state.cashedOutBets[roundId][couponId];
      }
    },
    clearCashedOutBets(state) {
      state.cashedOutBets = {};
    },
  },
});

export const betHistoryReducer = betHistorySlice.reducer;

export const {
  loadBetHistory,
  loadMoreBetHistory,
  loadBetHistorySuccess,
  loadBetHistoryFail,
  setSelectedBet,
  reloadBetHistory,
  betCashoutSuccess,
  betCashoutFail,
  tryCashoutSaga,
  bulkBetCashoutSuccess,
  clearCashedOutBets,
} = betHistorySlice.actions;
