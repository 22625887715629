import React, { Suspense, useEffect, useState, useCallback, useRef } from 'react';
import { TranslationProvider } from 'reactjs-polyglot';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import UserMenu from './components/UserMenu';
import SideMenu from './components/SideMenu';
import SVGProvider from './components/SVG/SVGProvider';
import {
  getActiveModal,
  getCashoutSlotClearance,
  getGameMode,
  getLastBetRoundTurbo,
  getOpenMenus,
  getRoundData,
  getSelectedLeague,
  getSelectedOdds,
} from './store/selectors';
import Modal from './components/modals';
import { GAME_MODE, LOADER_STATUS, MENUS, PAGES, PUSH_SERVER_URL } from './constants/globals';
import MainLayout from './components/MainLayout';
import { useLiveCountdownTimer } from './modules/live/hooks/useLiveCountdownTimer';
import LiveLoader from './modules/odds/components/LiveLoader';
import { kickOff, loadRound, setActiveModal, setCashoutSlotClearance, setLastCashoutSlot } from './store/reducer';
import ErrorFallback from './components/ErrorFallback';
import { getOddsLoadingStatus } from './modules/odds/store/selectors';
import Splash from './components/Splash';
import { WebSocketContext } from './contexts/SocketContext';
import { useWebSocket } from './hooks/useWebSocket';
import { clearCashedOutBets } from './modules/bet-history/store/slice';
import { applyServerOffset } from './utils/common';

const App = () => {
  const CNT_PATH = process.env.NODE_ENV === 'development' ? '/img' : `${process.env.CNT_PATH}/img`;
  const location = useLocation();
  const dispatch = useDispatch();

  const [isBetslipOpen, setIsBetslipOpen] = useState(false);

  const activeModal = useSelector(getActiveModal);
  const openMenus = useSelector(getOpenMenus);
  const selectedOdds = useSelector(getSelectedOdds);
  const roundData = useSelector(getRoundData);
  const gameMode = useSelector(getGameMode);
  const selectedLeague = useSelector(getSelectedLeague);
  const oddsLoadingStatus = useSelector(getOddsLoadingStatus);
  const cashoutSlotClearance = useSelector(getCashoutSlotClearance);
  const lastBetRoundForTurbo = useSelector(getLastBetRoundTurbo);

  const webSocket = useWebSocket(PUSH_SERVER_URL);
  const previousRoundId = useRef(null);

  const currentWeek =
    roundData && selectedLeague ? roundData.weeks[roundData.competitions.findIndex((x) => x === selectedLeague.id)] : 0;

  const timer = useLiveCountdownTimer(roundData?.endDate);

  const onMessage = useCallback(
    (message) => {
      const socketMessage = JSON.parse(message.data);
      const slotIsPast = applyServerOffset(new Date()) > new Date(socketMessage.slotEndDate);
      if (!slotIsPast) {
        dispatch(setLastCashoutSlot(socketMessage));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    setIsBetslipOpen(location?.pathname.includes(PAGES.BETSLIP));
  }, [location?.pathname]);

  useEffect(() => {
    const excludedPages = {
      [PAGES.HOME]: PAGES.HOME,
      [PAGES.LOGIN]: PAGES.LOGIN,
    };

    if (!roundData && !excludedPages[location?.pathname]) {
      dispatch(loadRound());
    }
  }, [dispatch, roundData, location?.pathname]);

  // logic to get next round for cashout games when timer ends and live starts
  useEffect(() => {
    if (timer === null) {
      return;
    }

    if (
      gameMode === GAME_MODE.CASH_OUT && timer <= 0 &&
      oddsLoadingStatus !== LOADER_STATUS.IN_PROGRESS &&
      previousRoundId.current === roundData?.roundId // prevent user from loading same round multiple times
    ) {
      dispatch(loadRound());
    }
    previousRoundId.current = roundData?.roundId;
  }, [timer, oddsLoadingStatus, dispatch, gameMode, roundData]);

  // logic to start turbo live when timer ends
  useEffect(() => {
    if (timer === null) {
      return;
    }

    if (
      gameMode === GAME_MODE.TURBO &&
      timer <= 0 &&
      roundData?.roundId === lastBetRoundForTurbo &&
      oddsLoadingStatus !== LOADER_STATUS.IN_PROGRESS) {
      dispatch(kickOff());
    }

  }, [timer, dispatch, oddsLoadingStatus, gameMode, roundData, lastBetRoundForTurbo]);

  useEffect(() => {
    if (webSocket?.client) {
      webSocket.client.addEventListener('message', onMessage);
    }

    return () => {
      if (webSocket?.client) {
        webSocket.client.removeEventListener('message', onMessage);
      }
    };
  }, [webSocket?.client, onMessage]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (cashoutSlotClearance?.endDate) {
        const isExpired = new Date(cashoutSlotClearance.endDate) < applyServerOffset(new Date());
        if (isExpired) {
          dispatch(setLastCashoutSlot(null));
          dispatch(setCashoutSlotClearance(null));
          dispatch(clearCashedOutBets());
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [cashoutSlotClearance, dispatch]);

  function showLiveLoader() {
    const hasOpenMenus = Object.values(openMenus).some((isOpen) => isOpen);
    const pagesNotToShowLiveLoader = {
      [PAGES.HOME]: PAGES.HOME,
      [PAGES.LOGIN]: PAGES.LOGIN,
      [PAGES.CASHIER]: PAGES.CASHIER,
      [PAGES.MY_DEPOSIT]: PAGES.MY_DEPOSIT,
      [PAGES.MY_ACCOUNT]: PAGES.MY_ACCOUNT,
    };
    const showLoader =
      timer !== null ? !hasOpenMenus && !pagesNotToShowLiveLoader[location?.pathname] && timer < 6 && timer > 0 : false;

    if (showLoader && activeModal) {
      dispatch(setActiveModal({ modal: null, data: {} }));
    }
    return showLoader;
  }

  function getOpenClass() {
    if (openMenus[MENUS.SIDE]) {
      return 'pmlIsOpen';
    }

    if (openMenus[MENUS.LEAGUE]) {
      return 'menuIsOpen';
    }

    if (openMenus[MENUS.BONUS]) {
      return 'bonusMenuIsOpen';
    }

    return '';
  }

  return (
    <SVGProvider cnt={CNT_PATH} wrapperClass={`wrapper ${getOpenClass()} ${isBetslipOpen ? 'betslipIsOpen' : ''}`}>
      <Suspense fallback={<Splash />}>
        <Sentry.ErrorBoundary fallback={ErrorFallback}>
          <TranslationProvider>
            <WebSocketContext.Provider value={webSocket?.client}>
              <MainLayout selectedOdds={selectedOdds}>
                <Outlet />
                <SideMenu />
                <UserMenu />
                <Modal />
                {showLiveLoader() && <LiveLoader week={currentWeek} seconds={timer} />}
              </MainLayout>
            </WebSocketContext.Provider>
          </TranslationProvider>
        </Sentry.ErrorBoundary>
      </Suspense>
    </SVGProvider>
  );
};

export default App;
