import React, { useEffect, useState } from 'react';
import { useTranslationContext } from 'reactjs-polyglot';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { GAME_MODE, LOADER_STATUS, MENUS, PAGES, SVG_ICONS } from '../constants/globals';
import {
  getIsAuthenticated,
  getIsCashoutEnabled,
  getIsClassicEnabled,
  getIsTurboEnabled,
  getUserBonus,
  getUserFreeBets,
} from '../modules/auth/store/selectors';
import { useIsLive } from '../modules/live/hooks/useIsLive';
import { useLiveCountdownTimer } from '../modules/live/hooks/useLiveCountdownTimer';
import CashoutLeagueList from '../modules/lobby/components/CashoutLeagueList';
import ClassicLeagueList from '../modules/lobby/components/ClassicLeagueList';
import TurboLeagueList from '../modules/lobby/components/TurboLeagueList';
import { getOddsLoadingStatus } from '../modules/odds/store/selectors';
import { setSelectedMarketId } from '../modules/odds/store/slice';
import { endLiveSaga, toggleMenu } from '../store/reducer';
import {
  get5Markets,
  getOpenMenus,
  getSelectedLeague,
  getConfigs,
  getRoundData,
  getGameMode,
  getCurrentWeek,
  getBetCountSchedule,
  getLeaguesForLeagueMenu,
  getLiveDates,
} from '../store/selectors';
import { calculateBonusTime, formatBonusTime } from '../utils/bonus';
import { applyServerOffset, formatTimer } from '../utils/common';
import { getMarketsFirstSubmarketId } from '../utils/odds';
import LoyaltyMenu from './LoyaltyMenu';
import LoyaltyMenuContent from './LoyaltyMenu/LoyaltyMenuContent';
import SVGComponent from './SVG/SVGComponent';

const LeagueBarInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { t } = useTranslationContext();

  const config = useSelector(getConfigs);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const freeBets = useSelector(getUserFreeBets);
  const selectedLeague = useSelector(getSelectedLeague);
  const leagues = useSelector(getLeaguesForLeagueMenu);
  const shownMarkets = useSelector(get5Markets);
  const isClassicEnabled = useSelector(getIsClassicEnabled);
  const isTurboEnabled = useSelector(getIsTurboEnabled);
  const isCashoutEnabled = useSelector(getIsCashoutEnabled);
  const openMenus = useSelector(getOpenMenus);
  const userBonus = useSelector(getUserBonus);
  const gameMode = useSelector(getGameMode);
  const roundData = useSelector(getRoundData);
  const leagueWeek = useSelector(getCurrentWeek);
  const betCountSchedule = useSelector(getBetCountSchedule);
  const loadingOddsStatus = useSelector(getOddsLoadingStatus);
  const liveDates = useSelector(getLiveDates);

  const isLive = useIsLive(liveDates?.startDate, liveDates?.endDate);
  const timer = useLiveCountdownTimer(roundData?.endDate);

  const [bonusTime, setBonusTime] = useState(null);

  useEffect(() => {
    let interval = null;
    if (isAuthenticated && userBonus?.active) {
      interval = setInterval(() => {
        const timeObj = calculateBonusTime(userBonus?.expirationDate);
        setBonusTime(timeObj);
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [userBonus?.expirationDate, userBonus?.active, isAuthenticated]);

  useEffect(() => {
    function getEndedRounds(betCountScheduleParam) {
      const schedule = betCountScheduleParam;
      const rounds = Object.keys(schedule);
      return rounds.filter((r) => new Date(schedule[r].time) < applyServerOffset(new Date()));
    }

    const interval = setInterval(() => {
      const endedRounds = getEndedRounds(betCountSchedule);

      // eslint-disable-next-line no-restricted-syntax
      for (const round of endedRounds) {
        dispatch(endLiveSaga({ roundId: round, page: location.pathname }));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [betCountSchedule, dispatch, location.pathname]);

  function selectLeague(mode) {
    const [market] = shownMarkets;
    if (market) {
      dispatch(
        setSelectedMarketId({
          selectedMarketId: market.id,
          selectedSubmarketId: getMarketsFirstSubmarketId(market),
        })
      );
    }
    dispatch(toggleMenu({ menu: MENUS.LEAGUE }));
    if (isLive && gameMode === mode) {
      navigate(PAGES.LIVE);
    } else {
      navigate(PAGES.ODDS);
    }
  }

  function toggleLeagueDropDown() {
    dispatch(toggleMenu({ menu: MENUS.LEAGUE }));
  }

  function getLeagueBonusClass() {
    if (userBonus?.bonusName) {
      return `league-bar--${userBonus.bonusName.toLowerCase()}`;
    }
    return '';
  }

  function getFlagIcon() {
    return gameMode === GAME_MODE.TURBO ? '-turbo' : '';
  }

  return (
    <div className={`league-bar ${getLeagueBonusClass()}`}>
      <div className="league-bar__info">
        <div className="league-bar__info-row">
          <div className="league-bar__info-flag mr-3">
            <span className={`league-bar__info-icon icon-${selectedLeague?.flag}${getFlagIcon()} md-icon`} />
          </div>
          <div className="league-bar__info-timer">
            {loadingOddsStatus !== LOADER_STATUS.IN_PROGRESS && timer ? formatTimer(timer) : null}
          </div>
          <div className="league-bar__info-dropdown" onClick={toggleLeagueDropDown}>
            <SVGComponent className="sm-icon" src={`img/${SVG_ICONS.utility}#icon__arrow-down`} />
          </div>
        </div>
        <div className="league-bar__info-row">
          <div className="league-bar__info-week">{t('common.word.week')} {leagueWeek}</div>
        </div>
      </div>

      {isAuthenticated ? (
        <LoyaltyMenu freeBets={freeBets} time={bonusTime} config={config} userBonus={userBonus} />
      ) : null}

      <div className={`league-bar__selections ${openMenus[MENUS.LEAGUE] ? 'is-open' : ''}`}>
        <div className="league-bar__selectionsWrap">
          <ClassicLeagueList
            leagues={leagues}
            selectLeague={selectLeague.bind(null, GAME_MODE.CLASSIC)}
            isEnabled={isClassicEnabled}
          />
          <CashoutLeagueList
            leagues={leagues}
            selectLeague={selectLeague.bind(null, GAME_MODE.CASH_OUT)}
            isEnabled={isCashoutEnabled}
          />
          <TurboLeagueList
            leagues={leagues}
            selectLeague={selectLeague.bind(null, GAME_MODE.TURBO)}
            isEnabled={isTurboEnabled}
          />
        </div>
      </div>

      {openMenus[MENUS.BONUS] ? (
        <LoyaltyMenuContent
          bets={freeBets}
          userBonus={userBonus}
          currencySymbol={config?.currencySymbol}
          time={formatBonusTime(bonusTime)}
        />
      ) : null}
    </div>
  );
};

export default LeagueBarInfo;
