import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslationContext } from 'reactjs-polyglot';
import { addStep } from 'user-step-tracker';
import { MENUS, PAGES, SVG_ICONS } from '../constants/globals';
import { getAppConfig, getDestinationUrls, getOpenMenus } from '../store/selectors';
import { getAuthIsLoading, getUser } from '../modules/auth/store/selectors';
import SVGComponent from './SVG/SVGComponent';
import { parseNum } from '../utils/parser';
import { convertToCentsDecimal } from '../utils/common';
import { toggleMenu } from '../store/reducer';

const TopBar = ({ onClickClose }) => {
  const { t } = useTranslationContext();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const destinationUrls = useSelector(getDestinationUrls);
  const openMenus = useSelector(getOpenMenus);
  const user = useSelector(getUser);
  const authIsLoading = useSelector(getAuthIsLoading);
  const config = useSelector(getAppConfig);
  const [isBetslipOpen, setIsBetslipOpen] = useState(false);
  const [isLoginPage, setIsLoginPage] = useState(false);

  useEffect(() => {
    setIsBetslipOpen(location?.pathname.includes('/betslip'));
    setIsLoginPage(location?.pathname.includes('/login'));
  }, [location?.pathname]);

  function close() {
    addStep('USER_CLOSE_TopBar');
    if (onClickClose && typeof onClickClose === 'function') {
      onClickClose();
    } else {
      navigate(-1);
    }
  }

  function toggleAccountMenu() {
    addStep('USER_TOGGLE_ACCOUNT_MENU_TopBar');
    dispatch(toggleMenu({ menu: MENUS.ACCOUNT }));
  }

  function navigateTo(page, isBack) {
    if (isBack === true) {
      addStep('USER_NAVIGATE_BACK_TopBar');
      navigate(-1);
      return;
    }
    addStep(`USER_NAVIGATE_TopBar_${page}`);
    navigate(page);
  }

  function goToBet9jaMobileSite() {
    addStep('USER_NAVIGATE_BET9JA_MOBILE_SITE_TopBar');
    window.location.href = destinationUrls.home;
  }

  function showBackButton() {
    return !openMenus[MENUS.SIDE] && !openMenus[MENUS.ACCOUNT] && location.pathname !== PAGES.HOME;
  }

  return (
    <div className={`top-bar ${isBetslipOpen ? 'betslipIsOpen' : ''}`}>
      <div className="top-bar__l">
        {showBackButton() ? (
          <div className="top-bar__navBtn" onClick={navigateTo.bind(null, PAGES.HOME, true)}>
            <SVGComponent className="sm-icon" src={`${SVG_ICONS.utility}#arrow-back`} />
            <span className="ml-2">{t('common.word.back')}</span>
          </div>
        ) : null}
      </div>
      <div className="top-bar__m" onClick={goToBet9jaMobileSite}>
        <span className="top-bar__logo" />
      </div>
      <div className="top-bar__r">
        {user ? (
          <div className="account mr-2">
            <div className="account__info text-right">
              <div className="account__info-label">{t('common.word.myAccount')}</div>
              <div className="account__info-number txt-cut">
                {user?.balance?.amount ? parseNum(convertToCentsDecimal(user.balance.amount)) : 0}{' '}
                {config?.currencySymbol}
              </div>
            </div>
            <div className="account__icon-holder ml-2">
              <div className="account__icon" onClick={toggleAccountMenu}>
                <SVGComponent
                  className="md-icon"
                  src={`${SVG_ICONS.utility}#${openMenus[MENUS.ACCOUNT] ? 'icon__close' : 'profile'}`}
                />
              </div>
            </div>
          </div>
        ) : null}
        {!authIsLoading && !openMenus[MENUS.SIDE] && !user && !isBetslipOpen && !isLoginPage ? (
          <div className="top-bar__navBtn" id="lobbyLoginBtn" onClick={navigateTo.bind(null, PAGES.LOGIN)}>
            <span className="mr-2">{t('common.word.login')}</span>{' '}
            <SVGComponent className="md-icon" src={`${SVG_ICONS.utility}#icon-login`} />
          </div>
        ) : null}

        <div className="top-bar__r-closeBtn" onClick={close}>
          <SVGComponent className="md-icon" src={`${SVG_ICONS.utility}#icon__close`} />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
