import { GAME_MODE } from '../../../constants/globals';

export const getAuthState = (state) => state?.auth;
export const getIsAuthenticated = (state) => !!state?.auth.user?.sessionId;

export const getIsClassicEnabled = (state) => state?.auth?.user?.data.gamesEnabled.some((g) => g === GAME_MODE.CLASSIC);

export const getIsTurboEnabled = (state) => state?.auth?.user?.data.gamesEnabled.some((g) => g === GAME_MODE.TURBO);

export const getIsCashoutEnabled = (state) =>
  getIsAuthenticated(state) ? state?.auth?.user?.data.gamesEnabled.some((g) => g === GAME_MODE.CASH_OUT) : true;

export const getUser = (state) => state?.auth?.user?.data;
export const getAuthIsLoading = (state) => state?.auth?.isLoading;
export const getUserBonus = (state) => state?.auth?.bonus;
export const getUserFreeBets = (state) => state?.auth?.user?.freeBets;

export const getUserEnabledGames = (state) => state?.auth?.user?.data.gamesEnabled || [];
