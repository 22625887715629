import React from 'react';
import { useTranslationContext } from 'reactjs-polyglot';

const LiveLoader = ({ week, seconds }) => {
  const { t } = useTranslationContext();

  return (
    <div className="live-loader">
      <div className="live-loader__content">
        <div className="live-loader__wrap">
          <div className="live-loader__info">
            <div className="live-loader__week">
              <span className="live-loader__week-txt">{t('common.word.week')} </span>
              <span className="live-loader__week-num ml-3">{` ${week}`}</span>
            </div>
            <div className="live-loader__txt">{t('live.label.commencingIn')}</div>
            <div className="live-loader__circ mt-5">
              <div className="live-loader__circOuter" />
              <div className="live-loader__circInner" />
              <div className="live-loader__circRotate">
                <svg height="200" width="200">
                  <circle cx="100" cy="100" r="80" className="live-loader__svg animDuration3" />
                </svg>
              </div>
              <div className="live-loader__counter">{seconds}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveLoader;
