import { createSlice } from '@reduxjs/toolkit';
import { LOADER_STATUS } from '../../../constants/globals';

const initialState = {
  selectedOdds: [],
  selectedMarketId: null,
  selectedSubmarketId: null,
  fifthMarketId: null,
  oddsLoadingStatus: LOADER_STATUS.START,
  leagueStatisticsLoadingStatus: LOADER_STATUS.START,
  leagueStatistics: {},
};

const oddsSlice = createSlice({
  name: 'odds',
  initialState,
  reducers: {
    setSelectedMarketId(state, action) {
      state.selectedMarketId = action.payload.selectedMarketId;
      state.selectedSubmarketId = action.payload.selectedSubmarketId;
    },
    setSelectedSubmarketId(state, action) {
      state.selectedSubmarketId = action.payload.selectedSubmarketId;
    },
    toggleSelectedOdd(state, action) {
      const { leagueId, matchId, selectionId, odd, selectionIndex, winningScores, name } = action.payload;
      const matchKey = `${leagueId}-${matchId}-${selectionId}`;

      const selectionPosition = state.selectedOdds.findIndex((x) => x.key === matchKey);
      if (selectionPosition === -1) {
        state.selectedOdds.push({
          key: matchKey,
          leagueId,
          matchId,
          selectionId,
          selectionIndex,
          odd: parseFloat(odd),
          winningScores,
          name,
        });
      } else {
        state.selectedOdds.splice(selectionPosition, 1);
      }
    },
    toggleSelectedOddSaga() {
      /** */
    },
    selectFifthMarket(state, action) {
      state.fifthMarketId = action.payload.fifthMarketId;
      state.selectedMarketId = action.payload.fifthMarketId;
      state.selectedSubmarketId = action.payload.selectedSubmarketId;
    },
    removeAllOddSelections(state) {
      state.selectedOdds = [];
    },
    loadLeagueStatistics(state, action) {
      if (action.payload.clearOthers) {
        const stats = state.leagueStatistics[action.payload.leagueId];
        state.leagueStatistics = { [action.payload.leagueId]: { ...stats } };
      }
      state.leagueStatisticsLoadingStatus = LOADER_STATUS.IN_PROGRESS;
    },
    loadLeagueStatisticsFail(state) {
      state.leagueStatisticsLoadingStatus = LOADER_STATUS.ERROR;
    },
    loadLeagueStatisticsSuccess(state, action) {
      state.leagueStatisticsLoadingStatus = LOADER_STATUS.START;
      state.leagueStatistics = {
        ...state.leagueStatistics,
        [action.payload.leagueId]: action.payload.data,
      };
    },
    clearOddsLeagueStatistics(state) {
      state.leagueStatistics = {};
    },
    setOddsLoaderStatus(state, action) {
      state.oddsLoadingStatus = action.payload.status;
    },
  },
});

export const oddsReducer = oddsSlice.reducer;

export const {
  setSelectedMarketId,
  setSelectedSubmarketId,
  toggleSelectedOdd,
  removeOddSelection,
  removeAllOddSelections,
  selectFifthMarket,
  loadLeagueStatistics,
  loadLeagueStatisticsFail,
  loadLeagueStatisticsSuccess,
  clearOddsLeagueStatistics,
  setOddsLoaderStatus,
  toggleSelectedOddSaga,
} = oddsSlice.actions;
