import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { goToAccountDeposit, goToCashier, goToMyAccount } from '../store/reducer';
import { getExternalIframeUrl } from '../store/selectors';
import { PAGES } from '../constants/globals';
import Spinner from './Spinner';

const ExternaliFrame = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const iframeUrl = useSelector(getExternalIframeUrl);
  const heightCache = useRef({});

  useEffect(() => {
    function postMessageListener(postMessage) {
      const jsonData = typeof postMessage.data === 'string' ? JSON.parse(postMessage.data).data : null;
      const height = jsonData?.height;

      if (!heightCache.current[height] && height !== undefined) {
        const element = document.getElementById('externalIframe');
        heightCache.current[height] = height;
        if (element) {
          element.style.height = `${height}px`;
        }
      }
    }

    window.addEventListener('message', postMessageListener);

    return () => {
      window.removeEventListener('message', postMessageListener);
    };
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case PAGES.MY_DEPOSIT:
        dispatch(goToAccountDeposit());
        break;
      case PAGES.MY_ACCOUNT:
        dispatch(goToMyAccount());
        break;
      case PAGES.CASHIER:
        dispatch(goToCashier());
        break;
      default:
        break;
    }
    heightCache.current = {};
    setIsLoading(true);
  }, [location, dispatch]);

  function onLoadIFrame() {
    setIsLoading(false);
  }
  return (
    <>
      {iframeUrl ? (
        <iframe
          title="iframe"
          id="externalIframe"
          width="100%"
          frameBorder={0}
          src={iframeUrl}
          style={{ backgroundColor: 'white' }}
          onLoad={onLoadIFrame}
        />
      ) : null}

      {isLoading ? <Spinner /> : null}

      <div className="scroll-area" />
    </>
  );
};

export default ExternaliFrame;
